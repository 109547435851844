<template>
  <main class="dark">
    <Navigation :user="user" :syncing="busy" :system="system" :offline="offline"/>
    <router-view v-slot="{ Component }">
      <component :is="Component"
                 :system="system"
                 :offline="offline"/>
    </router-view>
    <div id="fOpts" v-if="showGoogleOpt">
      <p>We use Google Analytics and anonymize your IP. If that is not okay with you, you can <span class="button" @click.prevent="gaOpt(true)">opt out here</span> or <span class="button" @click.prevent="gaOpt(false)">Accept</span>
      </p>
    </div>
    <div style="position: fixed;bottom: 0; left:0;font-size: 9px;z-index:111;padding:5px;">v{{ appState.packageVersion }}</div>
  </main>
</template>

<script>
/* eslint-disable */
import { onBeforeUnmount, onMounted, ref, watch } from "vue"
import { storeToRefs }                            from 'pinia'
import { bootstrap }           from 'vue-gtag'
import { useRoute, useRouter } from "vue-router";
import { useI18n }             from "vue-i18n";
import { useUserStore }                           from "@/store/user";
import { useProjectCollectionStore }              from "@/store/projectCollection";
import Navigation                                 from "@/components/Navigation";
import Animation                                  from "@/components/Animation";
import HelperOverlay                              from "@/views/HelperOverlay";
import { appStateStore }                          from "@/store/appState";
import { apiGetUserFromSession, sync }            from "@/store/api";
import { notify }                                 from "@kyvg/vue3-notification";
import { useProjectStore }                        from "@/store/project";

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()

    const appState = appStateStore()
    const {system, syncing, wait} = storeToRefs(appState)
    const userStore = useUserStore()
    const {user} = storeToRefs(userStore)
    const projectCollStore = useProjectCollectionStore()
    const {busy, projects} = storeToRefs(projectCollStore)
    const currentProjectStore = useProjectStore()
    const {project} = storeToRefs(currentProjectStore)

    const showGoogleOpt = ref(false)

    // Sync all projects across devices on a cloud account
    // This will only sync the project list
    let offline = ref(false)

    // Did user opt-in./out on analytics?
    let uOpted = false

    onMounted(() => {
      // Online state
      window.addEventListener('offline', () => {
        offline.value = true
      })
      window.addEventListener('online', () => {
        offline.value = false
      })

      // Get user
      userStore.getUser().then(() => {
        // Language pref
        if (user.value && i18n?.locale)
          i18n.locale.value = user.value && user.value?.metadata.locale || navigator.language.substr(0, 2)

        // GoogleAnalytics.
        uOpted = user.value.metadata.gaOptIn
        showGoogleOpt.value = (!system.value.app && (uOpted === null || uOpted === undefined))
        // Naughty: enable GA if user does not react
        setTimeout(() => {
              if (uOpted === null || uOpted === undefined)
                gaOpt(false)
            }, 20000
        )
      })


    })

    onBeforeUnmount(() => {
      // Todo: cleanup events
      disableSync()
    })

    watch(() => user.value, async (nv, ov) => {
      if (nv !== ov && nv?.authenticated) {
        // Note: this could take a long time. SYNC should do. Watch this!
        wait.value = true
        if (DBSYNC) {
          DBSYNC.cancel()
        }
        // Cloud user: initial pull
        // if (user.value && user.value?.authenticated) {
        //   await pullFromLive(user.value.name)
        //   .catch(e => {
        //     wait.value = false
        //   })
        // }
        // Initial refresh
        await projectCollStore.getProjects()
        wait.value = false
        enableSync()  // trigger sync when user is logged in.
      }
    })

    // ----- Database syncing.
    // Trigger initial pull when user changes
    let DBSYNC = null
    const enableSync = () => {
      // Init sync and watch --> check offline state
      // Start DB Sync
      DBSYNC = sync(user.value.name)
      DBSYNC.on('change', async (info) => {
        // syncing.value = true // won't do anything when live :::
        console.info('Sync CHANGE :: ', info)
        // Only downstream
        if (info.direction !== 'pull') {
          syncing.value = false
          wait.value = false
          return
        }
        // SHow waiting overlay, if this is the first pull
        // console.warn('projects = ', projects.value.length, router.currentRoute.value.name)
        if (!projects.value.length)
          wait.value = true
        // Refresh all projects, IF home:
        if (router.currentRoute.value.name === 'ProjectManager') {
          projectCollStore.getProjects()
        } else if (router.currentRoute.value.name === 'Editor') {
          // Refresh current project on the fly, if this rev is lower than the sent one
          // Todo: This is EXPERIMENTAL
          console.info(info.change.docs[info.change.docs.length - 1]?._rev.split('-')[0] + ' vs ' + project?.value._rev.split('-')[0])

          if (+info.change.docs[info.change.docs.length - 1]?._rev.split('-')[0] > +project?.value._rev.split('-')[0]) {
            // REFRESH DIRECTLY. Maybe notify the user? No, because it is a single user. The user will not work parallel.
            // currentProjectStore.getProject(route.params.projectId)
            // console.info('::::: UPDATE AVAILABLE ', info.change.docs[info.change.docs.length - 1]?._rev.split('-')[0], info.change.docs[info.change.docs.length - 1]._revisions)
          }
        }
      })
      .on('complete', function () {
        console.info('SYNC ::::::COMPLETE::::')
        // Won't trigger on live : https://stackoverflow.com/questions/43138371/pouchdb-sync-not-giving-a-complete-event
        syncing.value = false
        wait.value = false
      })
      .on('error', function (err) {
        console.warn('yo, we got an error!', err)
        notify(i18n.t('could_not_sync'))
        syncing.value = false
        wait.value = false
        if (err.error === 'unauthorized') {
          // Retry session login
          apiGetUserFromSession()
        }
      })

    }

    // Stop sync
    const disableSync = () => {
      if (DBSYNC) DBSYNC.cancel()
      syncing.value = false
      wait.value = false
    }

    // -----  Google Analytics opt-out

    /**
     * Opt in or out of GAnalytics
     * @param out
     */
    const gaOpt = (out) => {
      if (uOpted) return
      showGoogleOpt.value = false
      userStore.setUserMeta({gaOptIn: !out})
      if (!out) {
        // enable GA plugin
        bootstrap().then((gtag) => {
          // all done!
        })
      }
    }

    return {
      offline,
      appState,
      system,
      user,
      busy,
      gaOpt,
      showGoogleOpt,
      // updateHelperOverlay
    }
  },
  components: {
    HelperOverlay,
    Animation,
    Navigation
  }
}
</script>

<style lang="scss">
@import "styles/variables";
@import "styles/mixins";
@import "styles/typography";
@import "styles/base";

// Icons
.jam {
  width  : 22px;
  height : 22px;
}

#pmToggle {
  z-index         : 2;
  position        : fixed;
  bottom          : 10px;
  left            : 10px;
  width           : 100px;
  height          : 100px;

  display         : flex;
  align-items     : center;
  justify-content : center;

  //border          : 4px solid gray;
  border-radius   : 50%;
  background      : rgba(255, 255, 255, 1);
  //background      : transparent;
  padding         : 5px;
  //box-shadow      : 0 0 0 10px rgb(0 0 0 / 3%), 0 0 0 10px rgb(255 255 255 / 22%) inset;
  box-shadow      : 0 0 0 0 rgb(240 10 0 / 40%), 0 0 0 10px rgb(255 255 255 / 22%) inset;

  img {
    width  : 100%;
    height : 100%;
  }

  transition      : all 680ms;

  //&.dirty {
  //  box-shadow : 0 0 0 10px rgb(240 10 0 / 40%), 0 0 0 10px rgb(255 255 255 / 22%) inset;
  //}

}

// Tags
.tag {
  display        : inline;
  text-transform : uppercase;
  padding        : 1px 10px;
  line-height    : 25px;
  background     : rgba(255, 255, 255, 0.2);
  border-radius  : 4px;
  margin         : 0 8px;

  &.red {
    background : rgba(255, 100, 0, 0.3);
  }

  // errors
  &.error {
    background : darkred !important;
  }

}

// --------------
// Buttons
// ~~ s. button component

// !!!
.dark {
  color : var(--text-color);
}

//// Switches
//.switch input {
//  position : absolute;
//  opacity  : 0;
//}
//
///**
// * 1. Adjust this to size
// */
//
//.switch {
//  display       : inline-block;
//  font-size     : 20px; /* 1 */
//  height        : 1em;
//  width         : 2em;
//  background    : #BDB9A6;
//  border-radius : 1em;
//}
//
//.switch div {
//  height             : 1em;
//  width              : 1em;
//  border-radius      : 1em;
//  background         : #FFF;
//  box-shadow         : 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
//  -webkit-transition : all 300ms;
//  -moz-transition    : all 300ms;
//  transition         : all 300ms;
//}
//
//.switch input:checked + div {
//  -webkit-transform : translate3d(100%, 0, 0);
//  -moz-transform    : translate3d(100%, 0, 0);
//  transform         : translate3d(100%, 0, 0);
//}

// White svg
svg.white {
  fill : white;
}

svg.red {
  fill : #f90;
}

svg.gray {
  fill : #6a6a6a;
}

svg.rotate {
  fill      : #f90;
  animation : rotationyeah infinite 1s;
}

@keyframes rotationyeah {
  from {
    transform : rotate(0deg);
  }
  to {
    transform : rotate(359deg);
  }
}

// Nav transitions
.fade-enter-active,
.fade-leave-active {
  transition : opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity : 0;
}

// Modals
.vfm--inset {
  display         : flex;
  justify-content : center;

  .vfm__content {
    width         : 50%;
    height        : auto;
    align-self    : center;
    background    : rgba(0, 0, 0, 0.7);
    padding       : 2rem;
    border-radius : 0.25rem;
    border        : 1px solid rgba(255, 255, 255, 0.3);

    .controls {
      display         : flex;
      justify-content : space-around;
      margin-top      : 1.5rem;
    }
  }
}

// Options band (bottom)
#fOpts {
  position   : fixed;
  z-index    : 500;
  bottom     : 0;
  width      : 100%;
  padding    : 0.05rem 1rem;
  background : rgba(255, 255, 255, 0.8);
  color      : black;
}


</style>
