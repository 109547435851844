import ArrowLeft      from "./components/arrow-left.vue"
import ArrowRight     from "./components/arrow-right.vue"
import ArrowUp        from "./components/arrow-up.vue"
import Close          from "./components/close.vue"
import BookF          from "./components/book-f.vue"
import Rubber         from "./components/rubber"
import ShareAlt       from "./components/share-alt"
import UserCircle     from "./components/user-circle"
import Undo           from "./components/undo"
import Redo           from "./components/redo"
import Mic            from "./components/mic-alt"
import LogIn          from "./components/log-in.vue"
import LogOut         from "./components/log-out.vue"
import MoreHorizontal from "./components/more-horizonotal"
import Home           from "./components/home"
import Folder         from "./components/folder-open"
import Flask          from "./components/flask"
import Trash          from "./components/trash"
import Box            from "./components/box"
import NoBox          from "./components/nobox"
import AlignLeft      from "./components/align-left"
import AlignCenter    from "./components/align-center"
import AlignJustify   from "./components/align-justify"
import AlignRight     from "./components/align-right"
import ArrowDown      from "./components/arrow-down"
import Plus           from "./components/plus"
import QRCode         from "./components/qrcode"
import Pencil         from "./components/pencil"
import Text           from "./components/text"
import Signal         from "./components/signal"
import Refresh        from "./components/refresh"
import Layers         from "./components/layers"
import LayersF        from "./components/layers-f"
import Download       from "./components/download"
import SplitLeft      from "./components/split-left"
import SplitRight     from "./components/split-right"
import Help           from "./components/help"
import FontBold       from "./components/font-bold"
import FontItalic     from "./components/font-italic"
import FontStrike     from "./components/font-strike"
import FontUnderline     from "./components/font-underline"
import FontH1         from "./components/font-h1"
import FontH2         from "./components/font-h2"
import FontH3         from "./components/font-h3"
import Li             from "./components/li"
import Ol             from "./components/ol"
import Quote          from "./components/quote"


import SaveIndicator from "@/components/icons/jam/components/SaveIndicator";

/**
 * Icon component
 * derived and shortened: $ npm install vue-icon-icons
 * https://github.com/michaelampr/vue-jam-icons/tree/master/components
 *
 * <icon-backpack/>
 *
 */

export default {
	install: function (Vue) {
		Vue.component("icon-save-indicator", SaveIndicator),
			Vue.component("icon-arrow-left", ArrowLeft)
		Vue.component("icon-arrow-right", ArrowRight)
		Vue.component("icon-arrow-down", ArrowDown)
		Vue.component("icon-arrow-up", ArrowUp)
		Vue.component("icon-close", Close)
		Vue.component("icon-book-f", BookF)
		Vue.component("icon-rubber", Rubber)
		Vue.component("icon-share-alt", ShareAlt)
		Vue.component("icon-user-circle", UserCircle)
		Vue.component("icon-undo", Undo)
		Vue.component("icon-redo", Redo)
		Vue.component("icon-log-in", LogIn)
		Vue.component("icon-log-out", LogOut)
		Vue.component("icon-more-horizontal", MoreHorizontal)
		Vue.component("icon-home", Home)
		Vue.component("icon-folder-open", Folder)
		Vue.component("icon-flask", Flask)
		Vue.component("icon-trash", Trash)
		Vue.component("icon-box", Box)
		Vue.component("icon-nobox", NoBox)
		Vue.component("icon-align-left", AlignLeft)
		Vue.component("icon-align-right", AlignRight)
		Vue.component("icon-align-justify", AlignJustify)
		Vue.component("icon-align-center", AlignCenter)
		Vue.component("icon-plus", Plus)
		Vue.component("icon-qrcode", QRCode)
		Vue.component("icon-pencil", Pencil)
		Vue.component("icon-mic", Mic)
		Vue.component("icon-text", Text)
		Vue.component("icon-signal", Signal)
		Vue.component("icon-refresh", Refresh)
		Vue.component("icon-layers", Layers),
			Vue.component("icon-layers-f", LayersF),
			Vue.component("icon-download", Download),
			Vue.component("icon-split-right", SplitRight),
			Vue.component("icon-split-left", SplitLeft),
			Vue.component("icon-help", Help),
			Vue.component("icon-font-bold", FontBold),
			Vue.component("icon-font-italic", FontItalic),
			Vue.component("icon-font-strike", FontStrike),
			Vue.component("icon-font-underline", FontUnderline),
			Vue.component("icon-font-h1", FontH1),
			Vue.component("icon-font-h2", FontH2),
			Vue.component("icon-font-h3", FontH3),
			Vue.component("icon-li", Li),
			Vue.component("icon-ol", Ol),
			Vue.component("icon-quote", Quote)

	}
}
