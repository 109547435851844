// https://dev.to/kevin_odongo35/integrate-paypal-with-vue-application-42ih
// https://www.labnol.org/paypal-subscriptions-node-210524

// https://www.tabnine.com/code/javascript/modules/paypal-rest-sdk
const Payapl = require('paypal-server-api');

// TODO: use VITE_ENV for these.
const isSandbox = true  // Payment live or dev
const paypalBaseUri = {
	sandbox: 'https://api-m.sandbox.paypal.com/',
	production: 'https://api-m.paypal.com/'
}
const client = {
	sandbox: {id: 'AVUCeloNvrFj7onaGZt8fH94IYsv1UMlryN2vo6uy5tAJK2-noL8rJdnDMAsx8GQeiOdRKPvQHZvFgKA', secret: 'EHmHrdExtqhbqcYO-yMreL0tg8QmMEvm_-LHGkvX-Mp9rN5fB0bh3Cs9iaF8diVVf73-xKMKWurCWUla'},
	production: {id: 'AZptJnwoWRDLkfnyDYgWPl718DTiTsaS3ZMstlPvOzOroCOq1Ptinu1w0KoM_9KUxozKyemvHK-niQ4W', secret: null}  // todo
}
const plans = {
	sandbox: {
		monthly: 'P-0VA768784S968902HMMPQJGA',  // tax ex
		yearly: 'P-29V08072KH022880TMMPQKLY'  // tax inc
	},
	production: {
		yearly: 'P-0UG56453UM712583PMMPDZGY',
		monthly: 'P-2CD99161HF4323627MEP3B6Y'
	}
}

export const getPaypalBaseUri = () => isSandbox ? paypalBaseUri.sandbox : paypalBaseUri.production
export const getClient = () => isSandbox ? client.sandbox : client.production
export const getPlans = () => isSandbox ? plans.sandbox : plans.production

const paypal = new Payapl({
	clientId: getClient().id,
	secret: getClient().secret,
	environment: isSandbox ? 'sandbox' :'production', // production | sandbox
	log: true,
});

// get user subscription details
export async function getUserSubscriptionDetails(subscriptionID) {
	await paypal.authenticate();
	const billingData = await paypal.execute(`v1/billing/subscriptions/${subscriptionID}`);

	return billingData;
}

export async function cancelSubscription(subscriptionID) {
	await paypal.authenticate();
	const billingData = await paypal.execute(`v1/billing/subscriptions/${subscriptionID}/cancel`,
		{
			method: 'post',
			body: {reason: 'Unknown'}
		});

	return billingData;
}

export async function suspendSubscription(subscriptionID) {
	await paypal.authenticate();
	const billingData = await paypal.execute(`v1/billing/subscriptions/${subscriptionID}/suspend`,
		{
			method: 'post'
		});

	return billingData;
}

export async function resumeSubscription(subscriptionID) {
	await paypal.authenticate();
	const billingData = await paypal.execute(`v1/billing/subscriptions/${subscriptionID}/activate`,
		{
			method: 'post'
		});

	return billingData;
}

