export const helpStates = [

	{
		id: 0,
		text: 'help.editor.add_item',
		x: 100,
		y: 100,
		arrow: 'down',
		s: {
			// clickConstraints: {x: 120, y: 220, x1: 220, y1: 340},    // User can only click within these bounds
			stagePanEnabled: false,
			nodeNavEnabled: true,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: true,
		lock: {x: 180, y: 220, x1: 340, y1: 320},
	},
	{

		id: 1,
		text: 'help.editor.add_content_and_click',
		arrow: 'left',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{
		id: 2,
		text: 'help.editor.add_another',
		arrow: 'down',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: true
	},
	{
		id: 3,
		text: 'help.editor.add_content_and_click_1',
		arrow: 'left',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{
		id: 4,
		text: 'help.editor.move_node_over',
		arrow: 'left',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{

		id: 5,
		text: 'help.editor.move_node_over_again',
		arrow: '',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{
		id: 6,
		text: 'help.editor.select_a_node',
		arrow: 'down',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{
		id: 7,
		text: 'help.editor.add_item_7',
		arrow: 'down',
		s: {
			stagePanEnabled: false,
			nodeNavEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
		dot: false
	},
	{
		id: 8,
		text: 'help.editor.add_item_8',
		arrow: 'down',
		dot: false,
		s: {
			stagePanEnabled: false,
			contextItemsEnabled: ['default', 'h1', 'h2'],
			highlightedContextItems: ['default', 'h1', 'h2']
		},
	},
	{
		id: 9,
		text: 'help.editor.add_item_9',
		arrow: '',
		dot: false,
		s: {
		},
	},
	{
		id: 10,
		text: 'help.editor.add_content_and_click0',
		arrow: 'up',
		dot: false,
		s: {
			nodeNavEnabled: true,
		},
	},
	{
		id: 11,
		text: 'help.editor.add_content_and_click1',
		arrow: 'down',
		dot: true,
		s: {
			nodeNavEnabled: false
		},
		x: 200,
		y: 300,
		lock: {x: 250, y: 490, x1: 440, y1: 580 }
	},
	{
		id: 12,
		text: 'help.editor.add_content_and_click2',
		arrow: 'left',
		dot: false,
		s: {
		},
	},
	{
		id: 13,
		text: 'help.editor.go_up',
		arrow: 'left',
		dot: false,
		s: {
		},
	},
	{
		id: 14,
		text: 'help.editor.split_1',
		arrow: 'up',
		dot: false,
		s: {
			nodeNavEnabled: true
		},
	},
	{
		id: 15,
		text: 'help.editor.split_2',
		arrow: 'right',
		dot: false,
		s: {
			stagePanEnabled: true
		},
	},
	{
		id: 16,
		text: 'help.editor.split_3',
		arrow: 'left',
		dot: false,
		s: {
			stagePanEnabled: true
		},
	},
	{
		id: 17,
		text: 'help.editor.split_4',
		arrow: '',
		dot: false,
		s: {}
	}


]
