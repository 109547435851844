// Utilities :)
import { useI18n } from "vue-i18n";
import config      from "@/config";

export function clamp(num, min, max) {
	return Math.min(Math.max(num, min), max)
}

export function debounce(func, timeout = 300) {
	let timer
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	}
}

/**
 * Check available local storag quota
 * @return {Promise<void>}
 */
export async function checkQuota() {
	if (navigator.storage && navigator.storage.estimate) {
		const quota = await navigator.storage.estimate();
		// quota.usage -> Number of bytes used.
		// quota.quota -> Maximum number of bytes available.
		const percentageUsed = (quota.usage / quota.quota) * 100;
		console.log(`You've used ${percentageUsed}% of the available storage.`);
		const remaining = quota.quota - quota.usage;
		console.log(`You can write up to ${remaining} more bytes.`);
	}
}

/**
 * Simple cipher algorithm. Use cryptojs for hardened security
 * [https://www.npmjs.com/package/crypto-js]
 *
 * Usage:
 * 		// To create a cipher
		const myCipher = cipher('mySecretSalt')

		//Then cipher any text:
		myCipher('the secret string')   // --> "7c606d287b6d6b7a6d7c287b7c7a61666f"

		//To decipher, you need to create a decipher and use it:
		const myDecipher = decipher('mySecretSalt')
		myDecipher("7c606d287b6d6b7a6d7c287b7c7a61666f")    // --> 'the secret string'
 *
 * @param salt
 * @return {function(*): *}
 */
export const cipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

	return text => text.split('')
	.map(textToChars)
	.map(applySaltToChar)
	.map(byteHex)
	.join('');
}

export const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
	.map(hex => parseInt(hex, 16))
	.map(applySaltToChar)
	.map(charCode => String.fromCharCode(charCode))
	.join('');
}

export const getSalt = () => {
	return 'Ffx0_*bGtca{)WDJ+2la32'	// well, this isn't secure, is it? best to use couchdb to generate
}

export const getLocalUserPrefs = () => {
	const uc = localStorage.getItem(config.localStorageKey) ? JSON.parse(localStorage.getItem(config.localStorageKey)) : null
	// If any password is stored then de-hash
	if (uc && uc.password) {
		const _decipher = decipher(getSalt())
		return {...uc, ...{password: _decipher(uc.password)}}
	}
	return uc
}

// @deprecated
export const setLocalUserPrefs = (prefs) => {
	const _prefs = {...getLocalUserPrefs(), ...prefs}
	localStorage.setItem(config.localStorageKey, JSON.stringify(_prefs))
}

/**
 * Return contrasting colour
 * @param color string of colour HEX | RGB | RGBA
 * @param bw boolean ~ just black or white
 * @return {string}
 */
export function contrast(color, bw = true) {
	let hex
	if (color.indexOf('#') === 0) {
		hex = color.slice(1)
	} else {
		// Might be rgba
		color = _rgba2hex(color)
		hex = color.slice(1)
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		// throw new Error('Invalid HEX color.');
		return ''
	}
	var r = parseInt(hex.slice(0, 2), 16),
		g = parseInt(hex.slice(2, 4), 16),
		b = parseInt(hex.slice(4, 6), 16);
	if (bw) {
		// https://stackoverflow.com/a/3943023/112731
		return (r * 0.299 + g * 0.587 + b * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}
	// invert color components
	r = (255 - r).toString(16);
	g = (255 - g).toString(16);
	b = (255 - b).toString(16);
	// pad each with zeros and return
	return "#" + _padZero(r) + _padZero(g) + _padZero(b);
}

export const calcColorContrast = (color) => {
	// Intercept any bShit.
	if (color === 'light') return 'black'
	if (color === 'dark') return 'white'
	if (color === 'transparent') return 'black'	// This should never happen!


	const _luma = () => {
		// Hex or rgb/a color?
		if (color.substr(0, 3) === 'rgb') {
			const matchColors = (color.substr(0, 4) === 'rgba')
				? /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
				: /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/
			const rgb = matchColors.exec(color);
			return (0.2126 * rgb[1]) + (0.7152 * rgb[2]) + (0.0722 * rgb[3]); // SMPTE C, Rec. 709 weightings
		} else {
			color = color.replace('#', '')
			let rgb = (typeof color === 'string') ? _hexToRGBArray(color) : color;
			return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
		}
	}

	const _hexToRGBArray = (color) => {
		if (color.length === 3)
			color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
		else if (color.length !== 6)
			throw('Invalid hex color: ' + color);
		var rgb = [];
		for (var i = 0; i <= 2; i++)
			rgb[i] = parseInt(color.substr(i * 2, 2), 16);
		return rgb;
	}

	// Calculate
	return (_luma(color) >= 165) ? 'black' : 'white';
}

export function validateEmail(email) {
	return String(email)
	.toLowerCase()
	.match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	)
}

export function dateFromUTC(utc) {
	const i18n = useI18n()
	const date = new Date(utc);
	const formatOptions = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		// hour:   '2-digit',
		// minute: '2-digit',
		// hour12: true
	};
	let dateString = date.toLocaleDateString(i18n.getDateTimeFormat(), formatOptions);

	// dateString = dateString.replace(',', '')
	// .replace('PM', 'p.m.')
	// .replace('AM', 'a.m.')

	return dateString
}


// export function html2Rtf(html) {
//
// 	// return html2Rtf(html)
//
//
// 	// return htmlToRtf.buildRtf(convertDataToHtml(completeHtml()?.html?.blocks))
//
// 	// const umlautMap = {
// 	// 	'\u00dc': 'UE',
// 	// 	'\u00c4': 'AE',
// 	// 	'\u00d6': 'OE',
// 	// 	'\u00fc': 'ue',
// 	// 	'\u00e4': 'ae',
// 	// 	'\u00f6': 'oe',
// 	// 	'\u00df': 'ss',
// 	// }
//
// 	// Unicode/utf? -> https://stackoverflow.com/questions/66275158/specify-utf-8-character-encoding-in-rtf-the-text-in-utf-8-format-is-correctly
// 	//
// 	// Set Editor content to all | then get HTML from it.
//
// 	// if (!(typeof html === "string" && html)) {
// 	// 	return null;
// 	// }
//
// 	// let tmpRichText, hasHyperlinks;
// 	// let richText = html;
// 	//
// 	// // Singleton tagsapp
// 	// richText = richText.replace(/<(?:hr)(?:\s+[^>]*)?\s*[/]?>/ig, "{\\pard \\brdrb \\brdrs \\brdrw10 \\brsp20 \\par}\n{\\pard\\par}\n");
// 	// richText = richText.replace(/<(?:br)(?:\s+[^>]*)?\s*[/]?>/ig, "{\\pard\\par}\n");
// 	//
// 	// // Empty tags
// 	// richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?\s*[/]>/ig, "{\\pard\\par}\n");
// 	// richText = richText.replace(/<(?:[^>]+)\/>/g, "");
// 	//
// 	// // Hyperlinks
// 	// richText = richText.replace(
// 	// 	/<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/ig,
// 	// 	"{{{\n");
// 	// tmpRichText = richText;
// 	// richText = richText.replace(
// 	// 	/<a(?:\s+[^>]*)?(?:\s+href=(["'])(.+)\1)(?:\s+[^>]*)?>/ig,
// 	// 	"{\\field{\\*\\fldinst{HYPERLINK\n \"$2\"\n}}{\\fldrslt{\\ul\\cf1\n");
// 	// hasHyperlinks = richText !== tmpRichText;
// 	// richText = richText.replace(/<a(?:\s+[^>]*)?>/ig, "{{{\n");
// 	// richText = richText.replace(/<\/a(?:\s+[^>]*)?>/ig, "\n}}}");
// 	//
// 	// // Start tags
// 	// richText = richText.replace(/<(?:b|strong)(?:\s+[^>]*)?>/ig, "{\\b\n");
// 	// richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/ig, "{\\i\n");
// 	// richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/ig, "{\\ul\n");
// 	// richText = richText.replace(/<(?:strike|del)(?:\s+[^>]*)?>/ig, "{\\strike\n");
// 	// richText = richText.replace(/<sup(?:\s+[^>]*)?>/ig, "{\\super\n");
// 	// richText = richText.replace(/<sub(?:\s+[^>]*)?>/ig, "{\\sub\n");
// 	// richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "{\\pard\n");
// 	//
// 	// // End tags
// 	// richText = richText.replace(/<\/(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "\n\\par}\n");
// 	// richText = richText.replace(/<\/(?:b|strong|i|em|u|ins|strike|del|sup|sub)(?:\s+[^>]*)?>/ig, "\n}");
// 	//
// 	// // Strip any other remaining HTML tags [but leave their contents]
// 	// richText = richText.replace(/<(?:[^>]+)>/g, "");
// 	//
// 	// // Prefix and suffix the rich text with the necessary syntax
// 	// richText =
// 	// 	"{\\rtf1\\ansi\n" + (hasHyperlinks ? "{\\colortbl\n;\n\\red0\\green0\\blue255;\n}\n" : "") + richText +
// 	// 	"\n}";
// 	//
// 	// return richText;
// }



// Private ---------------------------

/**
 * Zero padding
 * @param str
 * @param len
 * @return {string}
 * @private
 */
function _padZero(str, len) {
	len = len || 2;
	var zeros = new Array(len).join('0');
	return (zeros + str).slice(-len);
}

/**
 * Convert rgb(a) to hex
 * Ignoring opacity(!)
 * @param orig
 * @return {string}
 * @private
 */
function _rgba2hex(orig) {
	let rgb = orig.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
	return (rgb && rgb.length === 4) ? "#" +
		("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
		("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
		("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}

