<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" :class="`icon ${spin ? 'spin' : ''}`">
    <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm7-8a6.99 6.99 0 0 1-2.89 5.666l-.53-.796L13 14a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4l1.2-1.6A6.99 6.99 0 0 1 17 10zm-7 3a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
  </svg>
</template>
<script>
export default {
  props: ['spin']
}
</script>
<style lang="scss" scoped>
@import "src/styles/variables";

.icon path {
  stroke-width : 0.5;
  opacity      : 0.1;
}

.icon.spin {
  path {
    opacity : 1;
  }

  animation : rotationBamm 1.2s linear infinite;
}


@keyframes rotationBamm {
  0% {
    transform : rotate(0deg);
  }
  100% {
    transform : rotate(360deg);
  }
}
</style>
