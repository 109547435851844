<template>
  <div id="modal">
    <slot/>
  </div>
</template>
<script>

</script>
<style lang="scss" scoped>
#modal {
  font-family      : sans;
  position         : fixed;
  //width           : 320px;
  top              : 50vh;
  left             : 50vw;
  transform-origin : 50% 0;
  transform        : translate3d(-50%, -50%, 0);
  background       : var(--background-trans);
  color            : var(--text-color);
  backdrop-filter  : blur(4px);
  border-radius    : 8px;
  padding          : 1rem;
  box-shadow       : rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 56px, rgba(17, 17, 26, 0.1) 0 24px 80px;
}
</style>
