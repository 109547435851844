import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		alias: '/projects',
		name: 'ProjectManager',
		component: () => import(/* webpackChunkName: "fizzler" */ '../views/ProjectManager.vue')
	},
	{
		path: '/edit/:projectId?',
		name: 'Editor',
		component: () => import(/* webpackChunkName: "fizzler" */ '../views/Editor.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "public" */ '../views/About.vue')
	},
	{
		path: '/options',
		name: 'Options',
		component: () => import(/* webpackChunkName: "public" */ '../views/Buy.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "public" */ '../views/Login.vue')
	},
	{
		path: '/resetpass/:username?/:code?',
		name: 'ResetPass',
		component: () => import(/* webpackChunkName: "public" */ '../views/ResetPass.vue')
	},
	{
		path: '/manuscript/:id',
		name: 'Manuscript',
		component: () => import(/* webpackChunkName: "fizzler" */ '../views/Manuscript.vue')
	},
	// {
	// 	path: '/signup',
	// 	name: 'Signup',
	// 	component: () => import(/* webpackChunkName: "fizzler" */ '../views/SignUp.vue')
	// },
]

const router = createRouter({
	history: createWebHashHistory(), //process.env.CORDOVA_PLATFORM ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
	routes
})

export default router
