import { defineStore, storeToRefs }                                                                          from 'pinia'
import { userModel }                                                                                         from "@/models";
import { apiGetUserFromSession, apiLogin, apiLogout, apiResetPass, apiSetUserMeta, apiSignUp, getLocalUser } from "@/store/api";
import { getUserSubscriptionDetails }                                                                        from "@/payment/payment";
import { useProjectStore }                                                                                   from "@/store/project";
import { appStateStore }                                                                                     from "@/store/appState";


const defaultUser = {...userModel}
/* eslint-disable */
export const useUserStore = defineStore('user', {
	state: () => {
		return {
			user: defaultUser,
			busy: false,
			error: null
		}
	},

	actions: {
		async getUser() {
			this.busy = true
			this.user = userModel   // empty user.
			// Try to login to remote db (cloud)
			await apiGetUserFromSession()
			.then(async r => {
				// If there is a remoteUser session, we can login to cloud (2)
				if (r?.user && r?.user._id) {
					// A live user is a cloud user
					this.user = {...defaultUser, ...r.user}
					this.user.authenticated = true

					// Get subscription details
					if (this.user.metadata.subscriptionId) {
						const subscriptionData = await getUserSubscriptionDetails(this.user.metadata.subscriptionId)
						this.user.subscription = subscriptionData
					}

					// myself
					if (this.user.roles.includes('admin')) {
						console.info('Welcome Admin.')
					}

					return this.user

				} else {
					// Local User.
					// Check the type of this app || The app level 0 || 1 depends on a paid app or not
					// Try to get the local user (= always "stranger" at this point)
					this.user = await getLocalUser()
					// console.info('2 GOT user', this.user)
					// return null
				}
			})
			.catch(() => {
				this.user = defaultUser
				// this.user = {...defaultUser, appLevel: system.value.app ? 1 : 0}
				console.warn('(UserSession:::) Nothing found: Staying with local user ', this.user.name)
				// return this.user
			})
			.finally(async () => {
				this.busy = false
				return this.user
			})
		},

		async setUserMeta(metadata) {
			await apiSetUserMeta(metadata)
		},

		/**
		 * Login
		 * @param credentials
		 * @return {Promise<T>}
		 */
		async login(credentials) {
			this.busy = true
			const {user, error} = await apiLogin(credentials)
			if (user && !error) {
				this.error = null
				this.user = {...user, ...{appLevel: 2, authenticated: true}}
			} else {
				this.error = {message: error.message}     // Todo: i18n
				this.user = defaultUser
			}
			this.busy = false
			return this.user
		},

		/**
		 * Log out user
		 * @return {Promise<T>}
		 */
		async logout() {
			this.busy = true
			return await apiLogout().then((r) => {
				if (r.ok) {
					// Reset user
					this.user = defaultUser
					// setLocalUserPrefs({...getLocalUserPrefs(), logout_forced: true})	// set logout_forced flag
					// Reset projects
					const projectStore = useProjectStore()
					projectStore.projects = []
					projectStore.project = null
				} else
					console.warn('Could not logout')
				return r
			})
			.finally(() => {
				this.busy = false
			})
		},

		async signUp(payload) {
			this.busy = true
			console.info('Signing up', payload)
			// Todo: return await is bullshit
			return await apiSignUp(payload)
			.then(r => {
				this.error = null
				// Instant login
				this.user = {...r.data, ...{appLevel: 2, authenticated: true}}
				return this.user
			}).catch(error => {
				if (error.response) {
					// Request made and server responded
					this.error = error.response.data
					this.user = defaultUser
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
			})
			.finally(() => {
				this.busy = false
			})
		},

		async resetPass(payload) {
			this.busy = true
			// Todo: return await is bullshit
			return await apiResetPass(payload)
			.then(r => {
				if (r.status === 200) {
					this.error = null
					return true
				}
				return false
			}).catch(error => {
				if (error.response) {
					// Request made and server responded
					this.error = error.response.data
					return null
					console.log(this.error)
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
					return null
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
					return null
				}
			})
			.finally(() => {
				this.busy = false
			})
		},

		save() {
			// we only save metadata. That's it
			return apiSetUserMeta(this.user.metadata)
		}


	},
	getters: {
		appLevel: state => {
			const appState = appStateStore()
			const {system} = storeToRefs(appState)
			// Admins are always cloud'
			if (!state.user || !state.user.roles) return 0
			if (state.user?.roles.includes('admin') || state.user?.roles.includes('beta')) return 2
			// Cloud user (2), default user -> isApp (1) or web (0)
			return state.user?.subscriptionData && state.user?.subscriptionData.status === 'ACTIVE' ? 2 : system.value.app ? 1 : 0
		}
	}
})
