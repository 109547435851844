export default {
	"en": {
		"error":   {
			"generic":                  "An error occurred",
			"offline":                  "You are offline.",
			"go_online":                "You need to get online to use this function",
			"database":                 "Could not reach the database.",
			"could_not_create_project": "Could not create project.",
			"could_not_sync":           "Could not sync your data. We're retrying..."
		},
		"nav":     {
			"Profile":          "profile",
			"Login":            "Login",
			"Signup":           "Sign up",
			"ProjectManager":   "project manager",
			"Editor":           "editor",
			"About":            "about fizzler",
			"Beta":             "Beta Tester",
			"betatester":       "become a beta tester",
			"signup_for_cloud": "Get the cloud version",
			"get_full_version": "Get the full version",
			"get_app":          "Get the app",
			"Buy":              "Upgrade",
			"Reset":            "Reset your password",
			"App_plans":        "App options"

		},
		"auth":    {
			"account":                "Your Account",
			"username":               "username",
			"password":               "password",
			"choose_password":        "Choose a password",
			"choose_your_plan":       "Choose your plan",
			"login":                  "Login",
			"logout":                 "Logout",
			"logged_in_as":           "You are logged in as",
			"enjoy_on_all_devices":   "Now you can enjoy your data on all devices.",
			"subscription":           "Your subscription",
			"yearly_subscription":    "Yearly",
			"monthly_subscription":   "Monthly",
			"subscription_amount":    "Amount",
			"subscription_renewal":   "Your subscription will renew on",
			"subscription_expiry":    "Your subscription will expire on",
			"cancel_subscription":    "Cancel my subscription",
			"pause_subscription":     "Pause my subscription",
			"renew_subscription":     "You can renew your subscription again if you like.",
			"renew_subscription_btn": "Renew your subscription",
			"or_signup":              "or sign up",
			"signup_success":         "You signed up successfully",
			"cloud_info":             "todo!",
			"reset_pass":             "Reset your password",
			"reset_pass_sent":        "Great. If you are registered with us you will receive an email with instructions to reset your password.",
			"login_if_account":       "Login, if you have an account already",
			"invalid_code":           "Your code seems to be invalid. Please try again.",
			"your_name":              "Your name",
			"your_email":             "Your email",
			"your_os":                "Your operating system",
			"forgot_password":        "Forgot your password?",
			"your_new_password":      "Your new password",
			"repeat_password":        "Repeat your password",
			"enter_email_to_reset":   "Just enter your email and we send you a reset link.",
			"reset_success":          "You password was successfully changed. You are now logged in.",

			"level_0":   "You are using the <strong>free version</strong> of the app. All data is stored in your browser and may be <strong>temporary</strong>. If you want to keep your data and have unlimited projects, check the ",
			"level_0_1": "options",
			"level_0_2": " to receive the full or cloud version.",
			"level_1":   "You are using the <strong>full version</strong> of the app. Your data is stored on your device and kept there until you delete the app. If you want to synchronise your data with multiple devices, check the options to receive the cloud version.",
			"level_2":   "You are using the <strong>cloud version</strong> of the app. Your data is stored on your device and on our cloud server. <br/>\n" +
				             "          If you want to cancel your description you ",
			"level_2_1": "can do it here."

		},
		"pm":      {
			"create":                 "create",
			"create_project":         "Go on, tap / click here to create your first project!",
			"add_project":            "Add project",
			"remove_project_confirm": "Do you want to get rid of this project?",
			"get_more":               "Want to create another?",
			"wait":                   "Please hold on while your projects are synchronised"
		},
		"about":   {
			"title":       "What is FIZZLER?",
			"main":        "It's a <strong>brainstorming app</strong> for writers, journalists, thinkers, philosophers &dash; for everyone! It's\n" +
				               "          designed to focus on your thoughts, not on the software. Make nodes, add connections in an <strong>associative way</strong> and get\n" +
				               "          into depth.",
			"howto_title": "How to use it",
			"howto":       " It should be simple enough to use. Just open the",
			"howto_1":     "and give it a go.",
		},
		"privacy": {
			"title": "Privacy",
		},
		"options": {
			"options":    "options",
			"font":       "font",
			"align":      "align",
			"size":       "size",
			"color":      "color",
			"border":     "border",
			"styles":     "styles",
			"background": "background",
			"roundness":  "roundness"
		},
		"help":    {
			"help":       "Help",
			"support":    "If you experience issues or have ideas for improvement or any comment, please feel free to",
			"start_tour": "Start tour",
			"end_tour":   "End tour",
			"editor":     {
				"add_item":                "Click and hold or double click to add a new node",
				"add_content_and_click":   "Sweet as. You created a new node! Add some content and click outside.",
				"add_another":             "Add another one! Remember: Double click or tap and hold.",
				"add_content_and_click_1": "Nice one. Fill in some content and click outside somewhere.",
				"move_node_over":          "Now click and hold the last node you created and move it over the other one. Once over it, release and see what is happening.",
				"move_node_over_again":    "And another task for you: click and drag either node on the other...",
				"select_a_node":           "You're a natural! This is how you connect / disconnect a node with another. <strong>Now select this node (tap or click).</strong>",
				"add_item_7":              "This node is now activated. Select a font down in the options panel to change it. Change any other property if you like.",
				"add_item_8":              "Nice looking? On to the second last step: Select a background in the options panel",
				"add_item_9":              "Even better! Now last thing: Select the second node.",
				"add_content_and_click0":  "Click on the little layer icon in this node to create a new subset of nodes to it.",
				"add_content_and_click1":  "<strong>You're awesome!</strong> This screen is a stage just like the one before. The only difference is that this one belongs to the node you have selected before. Create a node. (Last task, promise!)",
				"add_content_and_click2":  "Add some content. Then click somewhere outside.",
				"go_up":                   "If you want to go up to the parent node, just click on the arrow. ",
				"split_1":                 "Now you're back on the main stage. You see the layer icon highlighted. One more thing: Click or tap the last icon to split the screen.",
				"split_2":                 "Great stuff. Now you see the contents of the stage below the selected node to the right. You can work in both and never lose the train of your thoughts. <strong>Lastly, select the first node on the left</strong> and click the arrow to the right.",
				"split_3":                 "On to the last: <strong>click or tap the arrow</strong> pointing to the right.",
				"split_4":                 "<strong>Great!</strong> We are done here.",
				"done":                    "We're done. <strong>Great job!</strong>"
			}
		},

		"_comma":            ".",
		"new_fizzle":        "My fizzle",
		"hello":             "hi there.",
		"word_one":          "word",
		"word_other":        "words",
		"words":             "words",
		"open":              "open",
		"continue":          "continue",
		"saving":            "saving",
		"full_version":      "Full version (coming)",
		"buy_full_version":  "Buy the full version",
		"full_version_desc": " <p>If you want the maximum number of projects with no limitations, get the <strong>Full App</strong>. You\n" +
			                     "              can\n" +
			                     "              download the app for free in any app store below and upgrade within.</p>\n" +
			                     "            <p>This incurs a single cost (for each app). If you want to use apps on multiple devices, say your desktop\n" +
			                     "              and\n" +
			                     "              your tablet, check out the cloud option.</p>",
		"buy_cloud":         "Better: Cloud and apps inc.",
		"cloud_version":     "Cloud is coming up...",
		"cloud_desc":        "<p>\n" +
			                     "              You can upgrade to the cloud version of the app which enables you to <strong>sync your projects across all\n" +
			                     "              devices</strong>, even the browser. The best of all: You get all apps for any platform for free.\n" +
			                     "            </p>",
		"btn_get_cloud":     "Get cloud now",

		"free_note":         "<strong>Note:</strong> Your data is stored locally in your browser. It may be gone when you clear your cache.",
		"why_cloud":         "  <h1>Why cloud?</h1>\n" +
			                     "          <p>As a <strong>cloud user</strong> you will be able to sync your data between all apps and even your browser. You will get all full versions of the app for available platforms.\n" +
			                     "          </p>\n" +
			                     "\n" +
			                     "          <p>The cloud plan will be billed <strong>monthly</strong> or <strong>yearly</strong>. You can pause or cancel this\n" +
			                     "            anytime, however, the plan will continue until the end of the subscription date.</p>",
		"user_exists":       "This username is taken",
		"no_valid_username": "This username is not valid. Use an Email!",
		"no_password_given": "Give us a password please. It's for your safety.",
		"joinbeta_h1":       "Uuuuhhhh... wanna become a beta tester?",
		"joinbeta":          "First of all, <strong>thanks for your interest</strong>! If you join the beta testing you will <strong>get free access to the full version</strong> once its up to date (iOS/ Android tablets only or MaxOS). But I won't promise you completion - it all depends on how much energy I can put\n" +
			                     "into it. I can promise though, I'll put in a lot.<br/><br/>",
		"join!":             "Join!",
		"coming_up":         "Full version is on the way. You will be able to create as many projects as you like and even sync them across devices. <strong></strong>",
		"scale_to_draw":     "Scale in to draw!",
		"chapter":           "Chapter"

	},
	"de": {
		"error":   {
			"generic":   "Ein allgemeiner Fehler trat auf",
			"offline":   "Du bist offline.",
			"go_online": "Gehe online, um diese Funktion zu nutzen.",
			"database":  "Die Datenbank ist nicht erreichbar."
		},
		"nav":     {
			"Profile":          "Profil",
			"Login":            "Einloggen",
			"Signup":           "Registrieren",
			"ProjectManager":   "Projektmanager",
			"Editor":           "editor",
			"About":            "Über Fizzler",
			"Beta":             "Beta Tester",
			"betatester":       "Werde Beta-Tester",
			"signup_for_cloud": "Hol' dir die cloud version",
			"get_full_version": "Hol' dir die Vollversion",
			"get_app":          "Vollversion",
			"Buy":              "Hol' dir das Upgrade",
			"Reset":            "Hol' dir ein neues Passwort",
			"App_plans":        "App Optionen"
		},
		"privacy": {
			"title": "Privatsphäre",
		},
		"about":   {
			"title":       "Was ist FIZZLER?",
			"main":        "Fizzler ist eine <strong>brainstorming App</strong> für Schreiber, Denker, Journalisten, für alle! " +
				               "          Die App ist designed, um den Fokus auf die schnelle, <strong>assoziative</strong> Gedankenerfassung zu legen. Statt Gedanken auf die Software zu verschwenden, gehst du hier in die Tiefe.",
			"howto_title": "Wie funktioniert das?",
			"howto":       " Das sollte einfach genug sein. Öffne einfach den",
			"howto_1":     "und probiere es aus!",

		},
		"auth":    {
			"account":                "Dein Konto",
			"username":               "Nutzername",
			"password":               "Passwort",
			"choose_password":        "Wähle ein Passwort",
			"choose_your_plan":       "Wähle dein Abbo",
			"login":                  "Einloggen",
			"logout":                 "Abmelden",
			"logged_in_as":           "Du bist angemeldet als",
			"enjoy_on_all_devices":   "Nun sind deine Daten auf allen deinen Geräten zugänglich.",
			"subscription":           "Dein Abbonement",
			"yearly_subscription":    "Jährlich",
			"monthly_subscription":   "Monatlich",
			"subscription_amount":    "Preis",
			"subscription_renewal":   "Das Abbonement erneuert sich am",
			"subscription_expiry":    "Dein Abbonenment endet am",
			"cancel_subscription":    "Abbo beenden",
			"pause_subscription":     "Pausiere mein Abbo",
			"renew_subscription":     "Du kannst dein Abbo hier fortsetzen, wenn du magst.",
			"renew_subscription_btn": "Abbo wiederherstellen",
			"or_signup":              "oder Registrieren",
			"signup_success":         "Deine Registrierung war erfolgreich",
			"cloud_info":             "todo!",
			"reset_pass":             "Passwort zurücksetzen",
			"reset_pass_sent":        "Okay! Dir wird eine Email gesandt, die dir hilft, dein Passwort zurückzusetzen.",
			"login_if_account":       "Melde dich an, wenn du ein Konto besitzt",
			"invalid_code":           "Dieser Code ist ungültig oder verbraucht. Bitte versuche das nochmal",
			"your_name":              "Dein Name",
			"your_email":             "Deine E-Mail",
			"your_os":                "Dein Betriebssystem",
			"forgot_password":        "Passwort vergessen?",
			"your_password":          "Wähle ein Passwort",
			"repeat_password":        "Wiederhole das Passwort",
			"enter_email_to_reset":   "Trage einfach deine E-Mail ein und wir schicken Dir Anweisungen zum Zurücksetzen.",
			"reset_success":          "Dein Passwort wurde geändert und du bist nun angemeldet.",

			"level_0":   "Du nutzt die <strong>freie Version</strong> der App. Alle Daten sind in deinem Browser gespeichert und sind womöglich <strong>temporär</strong>. Willst du deine Daten behalten, schaue in die ",
			"level_0_1": "Optionen",
			"level_0_2": " für Voll- und Cloudversionen.",
			"level_1":   "Du nutzt die <strong>Vollversion</strong> der App. Deine Daten werden auf deinem Gerät gespeichert, bis du die App löscht. Wenn du deine Daten mit mehreren Geräten synchronisieren willst, schaue in die Upgrade-Optionen für die Cloud-Version.",
			"level_2":   "Du nutzt die <strong>Cloud Version</strong>. Deine Daten sind lokal gesichert und werden zentral syncronisiert. <br/>\n" +
				             "          Wenn du dein Abbo beenden willst,  ",
			"level_2_1": "kannst du es hier tun."
		},
		"pm":      {
			"create":                 "anlegen",
			"create_project":         "Na los, erstelle dein erstes Projekt!",
			"add_project":            "Neues Projekt",
			"remove_project_confirm": "Willst du das Projekt löschen?",
			"get_more":               "Willst du weitere Projekte anlegen?",
			"wait":                   "Bitte warte, deine Projekte werden synchronisiert"
		},
		"options": {
			"options":    "Optionen",
			"font":       "Schriftart",
			"align":      "Ausrichtung",
			"size":       "Größe",
			"color":      "Farbe",
			"border":     "Rahmen",
			"styles":     "Stile",
			"background": "Hintergrund",
			"roundness":  "Rundung"
		},
		"help":    {
			"help":       "Hilfe",
			"start_tour": "Tour starten",
			"end_tour":   "Tour beenden",
			"add_item":   "Klicke und halte, um eine neue Node anzulegen.",
			"support":    "Fehler gefunden? Fragen? Ideen? Setze dich mit uns in Kontakt.",
			"editor":     {
				"add_item":                "Tippe und halte oder klicke und halte, um eine neue Node anzulegen.",
				"add_content_and_click":   "Okay! Du hast eine neue Node angelegt. Fülle sie mit Inhalt und tippe / klicke danach einfach irgendwo ausserhalb.",
				"add_another":             "Füge noch eine hinzu. (Tippen / klicken und halten)",
				"add_content_and_click_1": "Einwandfrei. Füge Inhalt hinzu und tippe oder klicke außerhalb.",
				"move_node_over":          "Tippe / klicke und halte die letze Node. Ziehe sie über die andere und laß' sie los.",
				"move_node_over_again":    "Eine neue Aufgabe für dich: tippe / klicke auf eine Node und ziehe sie auf eine andere. Wenn du über der anderen Node bist, lass einfach los.",
				"select_a_node":           "Super! Jetzt selektiere diese Node mit einem einfachen Tap / Klick",
				"add_item_7":              "Diese Node ist nun aktiv. Die Optionen sind nun verfügbar. Ändere einfach mal die Schriftart!",
				"add_item_8":              "Sieht gut aus? Jetzt zum Hintergrund: Klicke in der Optionenleiste auf den Kreis unter \"Hintergrund\" und wähle einen aus.",
				"add_item_9":              "Noch besser! Wähle eine Node aus.",
				"add_content_and_click0":  "Klicke auf das Layer-Icon, um eine Ebene in die Tiefe zu gelangen.",
				"add_content_and_click1":  "<strong>Super!</strong> Dieser Bildschirm gehört zur Node, bei welcher du gerade auf das Layer-Icon getippt hast. Lege eine neue an - Doppelklick oder tippe und halte...",
				"add_content_and_click2":  "Füge Inhalt hinzu und klicke / tippe irgendwo ausserhalb.",
				"go_up":                   "Um eine Ebene herauf zu gehen, klicke auf den Pfeil nach oben.",
				"split_1":                 "Du bist wieder auf der Hauptebene. Das Layer-Icon ist nun aktiv, denn es gibt Inhalte unterhalb dieser Node. Klicke / tippe auf das letzte Icon, um den Bildschirm zu teilen.",
				"split_2":                 "Du siehst die Inhalte der ausgewählten Node nun hier. <strong>Aktiviere die erste Node auf der linken Seite</strong> und klicke / tippe auf den Pfeil nach rechts.",
				"split_3":                 "On to the last: <strong>click or tap the arrow</strong> pointing to the right.",
				"split_4":                 "<strong>Great!</strong> We are done here.",
				"done":                    "We're done. <strong>Great job!</strong>"
			}
		},

		"hello":             "Moin",
		"new_fizzle":        "Mein Fizzle",
		"word_one":          "Wort",
		"word_other":        "Wörter",
		"words":             "Wörter",
		"monthly":           "monatlich",
		"yearly":            "jährlich",
		"_comma":            ",",
		"continue":          "weiter",
		"saving":            "speichere",
		"open":              "öffnen",
		"free_note":         "<strong>Achtung:</strong> Deine Daten werden in dieser Version nur in deinem Browser gespeichert. Wenn du deinen Cache löscht, verschwinden sie womöglich.",
		"full_version":      "Vollversion (kommt)",
		"cloud_version":     "Cloud (kommt)",
		"buy_full_version":  "Kaufe die Vollversion",
		"full_version_desc": " <p>Wenn du eine unlimitierte Anzahl von Projekten und Ebenen willst, hole dir die <strong>Vollversion</strong>. Du\n" +
			                     "              kannst dir die App kostenlos herunterladen und darin upgraden.</p>\n" +
			                     "            <p>Das bedeutet eine Einmalzahlung pro App. Wenn Du mehrere Geräte verbinden willst, schaue dir die Cloud-Version an. Dabei sind alle Apps kostenlos.</p>",
		"buy_cloud":         "Besser: Cloud und Apps inkl.",
		"cloud_desc":        "<p>\n" +
			                     "              Du kannst innerhalb der Apps auf Cloud upgraden (oder gleich hier!), was die erlaubt\n" +
			                     "              <strong>alle Geräte untereinander zu synchronisieren</strong>, auch den Browser. Das beste: Die Vollversionen für alle Plattformen sind dabei inbegriffen..\n" +
			                     "            </p>",
		"btn_get_cloud":     "Hol' dir die Cloud-Version",
		"why_cloud":         "  <h1>Wozu Cloud?</h1>\n" +
			                     "          <p>Als <strong>Cloud Nutzer</strong> bist du in der Lage, deine Daten zwischen allen Apps zu synchronisieren. Auch im Browser - überall. Außerdem brauchst du keine Vollversion der App: Diese sind für alle gängigen Plattformen darin enthalten.\n" +
			                     "          </p>\n" +
			                     "\n" +
			                     "          <p>Abgerechnet wird <strong>monatlich</strong> oder <strong>jährlich</strong>. Du kannst jederzeit \n" +
			                     "            kündigen. Der Vertrag läuft dann zum Ende des gewählten Zeitraumes aus.</p>",

		"user_exists":       "Dieser Nutzername ist schon in Benutzung",
		"no_valid_username": "Bitte gib eine E-Mail-Adresse an.",
		"no_password_given": "Passwort bitte. Sonst kommt hier niemand rein.",
		"joinbeta_h1":       "Aaaaah... interessiert?",
		"joinbeta":          "Zunächst <strong>danke für dein Interesse</strong>! Um ein beta-Tester zu werden, trage dich hier ein. Du bekommst die Vollverversion der App* kostenlos (nur iOS/ Android Tablets oder MacOS)!",
		"join!":             "Mitmachen!",
		"coming_up":         "Die Vollversion ist auf dem Weg. Du kannst so viele Projekte anlegen, wie du magst und die Cloud-Version wird die Synchronisation in Echtzeit mit allen Geräten ermöglichen. MacOS, iOS, Windows, Android und Browser.<strong></strong>",
		"scale_to_draw":     "Zoome ein, um zu zeichnen!",
		"chapter":           "Kapitel"

	}
}
