// import { Capacitor } from "@capacitor/core";

const config = {
	isDevelopment: (process.env.NODE_ENV === 'development'),
	// serviceUrl: (process.env.NODE_ENV === 'development' && !Capacitor.isNativePlatform()) ? 'http://service.fizzler.local' : 'https://service.fizzler.app',
	// dbUrl: (process.env.NODE_ENV === 'development' && !Capacitor.isNativePlatform()) ? 'http://localhost:5984' : 'https://api.fizzler.app',
	// serviceUrl: 'https://service.fizzler.app',
	// dbUrl: 'https://api.fizzler.app',
	dbUrl: 'http://localhost:5984',
	serviceUrl: 'http://service.fizzler.local',
	localStorageKey: '@:fzz:prefs'
}
export default config
