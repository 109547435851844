import { defineStore, storeToRefs }       from 'pinia'
import { apiGetProjects, apiSaveProject } from "@/store/api";
import { useUserStore }                   from "@/store/user";
import { projectModel }                   from "@/models";
import { uuid }                           from "vue-uuid";
import { apiDeleteProject }               from "./api";
import { stageModel }                     from "../models";
import { appStateStore }                  from "./appState";

export const useProjectCollectionStore = defineStore('projects', {
	state: () => {
		return {
			projects: []
		}
	},

	actions: {
		/**
		 * Load all projects assoc to user (todo)
		 * @return {Promise<void>}
		 */
		async getProjects() {
			const userStore = useUserStore()
			const {user} = storeToRefs(userStore)

			const appState = appStateStore()
			appState.busy = true

			// If the user is level 1+
			try {
				let res = await apiGetProjects(user.value)
				console.log('Total of ' + res.total_rows + ' projects')
				if (res && res.rows) {
					let p = res.rows
					this.projects = []
					this.projects = p.map(pr => pr.doc) //
					this.projects = this.projects.sort((a, b) => a.order - b.order)
					appState.busy = false
				}
			} catch (err) {
				console.log(err);
				appState.busy = false
			}


		},

		/**
		 * Update project order (slow)
		 * @param payload
		 */
		async updateProjectList(payload) {
			// ReOrder
			const appState = appStateStore()
			appState.busy = true

			let results = []
			for (const [index, val] of payload.entries()) {
				let newP = {...val, ...{order: index}}
				this.projects[index].order = newP.order
				results.push(await apiSaveProject(newP))
			}
			Promise.all(results).then(() => {
				this.getProjects()
				appState.busy = false
			})
			return true
		},

		/**
		 * Create a new project
		 * @param payload
		 * @return {Promise<void>}
		 */
		async addProject(payload = {title: "new fizzle", order: 0}) {

			const {title, order} = payload
			const userStore = useUserStore()
			const {user} = storeToRefs(userStore)

			let _project = {
				...projectModel, ...{
					_id: `project_${uuid.v4()}`,
					// id: uuid.v4(),
					title,
					author: user.value.name,
					currentParentNodes: [{id: 0}, {id: 0}],
					stages: [{...stageModel}],
					nodes: [],
					connectors: [],
					order
				}
			}
			_project = await apiSaveProject(_project, true)
			this.$patch(_project)

			return _project
		},

		async updateProject(payload) {
			return await apiSaveProject(payload)
		},

		/**
		 * Delete a project
		 * @param payload STRING just the id of the project
		 * @return {Promise<void>}
		 */
		async deleteProject(payload) {
			const res = await apiDeleteProject(payload)
			this.state = null
			await this.getProjects()
			return res
		},

	},

	getters: {
		// currentProjects: (state) => state.projects.filter(p=>!p.archived).sort((a, b) => a.order - b.order),
		archivedProjects: (state) => state.projects.filter(p => p.archived).sort((a, b) => a.order - b.order)

	}
})
