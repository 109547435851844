export const userModel = {
	_id: 'stranger',
	name: 'stranger',	// always an email.
	password: '',
	roles: [],                  // ['cloud', 'admin']
	authenticated: false,       // internal
	metadata: {
		// Sub
		subscriptionId: null,
		subscriptionProvider: null,
		// preferences
		locale: navigator.language.substr(0, 2),
		helpEnabled: false,
		gaOptIn: null
	},
	subscription: null
}

export const stageModel = {
	id: 0,              // the id of the parent node
	title: '',          // The parent node's title, if any
	order: 0,
	position: {x: 0, y: 0, scale: 1},
	background: null,
	style: 'light',                // light || dark : Indicates a dark background and calls for inversion of colors
}

export const projectModel = {
	_id: null,      // DB id
	version: 2,
	author: null,
	device: '',
	title: 'My fizzle',
	archived: 0,    // nyi
	order: 0,
	categories: [],   // n.y.i.
	currentParentNodes: [{id: 0}, {id: 0}],
	currentParentNodePointer: 0,
	stages: [
		stageModel      // Always have stage id = 0 in it.
	],
	nodes: [],
	connectors: [],
	hasAttachment: false,
	time: null,
	_attachments: {}
}

export const nodeOptions = {
	id: 'default',
	content: '',
	align: '',  // left, center, right, block
	fontFamily: '',
	fontSize: 12.5,
	color: 'inherit',
	borderSize: 0,
	borderColor: '',
	borderRadius: 4,
	backgroundColor: ''
}

export const nodeModel = {
	id: null,
	type: 'default',	// default | sketch | audio | ... todo:
	order: 0,
	hasAttachment: false,   //
	content: '...',                             // the node text itself
	html: {},                                   // the rt editor content (main text)
	htmlIncluded: true,                         // included in manuscript?
	position: {x: 0, y: 0, w: 220, h: 30},
	options: nodeOptions,
	time: null
}


// NIY
export const preferenceModel = {
	// The stage options
	stageOptions: {},
	// How the default node should look like (NYI)
	nodeOptions: nodeOptions
}

export const predefNodeStyles = [
	{id: 'default', fontSize: 12.5, border: 'none', borderRadius: 0, backgroundColor: 'transparent', color: 'inherit', align: 'left'},
	{id: 'h1', fontSize: 30, border: '2px solid', borderRadius: 12, align: 'center'},
	{id: 'h2', fontSize: 24, border: '1px dashed', borderRadius: 6, align: 'center'},
	{id: 'h3', fontSize: 16, border: '1px dotted', borderRadius: 3},
	{id: 'hi1', border: '1px solid', borderRadius: 4, color: '#ffffff', backgroundColor: 'rgba(89, 28, 11, 0.9)'},

]


/*
Subscription model / PP

{
    "status": "ACTIVE",
    "status_update_time": "2022-09-12T10:10:42Z",
    "id": "I-YF498PUKF42J",
    "plan_id": "P-29V08072KH022880TMMPQKLY",
    "start_time": "2022-09-12T10:09:49Z",
    "quantity": "1",
    "shipping_amount": {
        "currency_code": "NZD",
        "value": "0.0"
    },
    "subscriber": {
        "email_address": "sb-tkfok20790032@personal.example.com",
        "payer_id": "998P5L3QQJ52N",
        "name": {
            "given_name": "John",
            "surname": "Doe"
        },
        "shipping_address": {
            "address": {
                "address_line_1": "Badensche Str. 24",
                "admin_area_2": "Berlin",
                "admin_area_1": "Berlin",
                "postal_code": "10715",
                "country_code": "DE"
            }
        }
    },
    "billing_info": {
        "outstanding_balance": {
            "currency_code": "NZD",
            "value": "0.0"
        },
        "cycle_executions": [
            {
                "tenure_type": "TRIAL",
                "sequence": 1,
                "cycles_completed": 1,
                "cycles_remaining": 0,
                "current_pricing_scheme_version": 1,
                "total_cycles": 1
            },
            {
                "tenure_type": "REGULAR",
                "sequence": 2,
                "cycles_completed": 0,
                "cycles_remaining": 12,
                "current_pricing_scheme_version": 1,
                "total_cycles": 12
            }
        ],
        "last_payment": {
            "amount": {
                "currency_code": "NZD",
                "value": "30.0"
            },
            "time": "2022-09-12T10:10:42Z"
        },
        "next_billing_time": "2023-09-12T10:00:00Z",
        "final_payment_time": "2034-09-12T10:00:00Z",
        "failed_payments_count": 0
    },
    "create_time": "2022-09-12T10:10:41Z",
    "update_time": "2022-09-12T10:10:42Z",
    "plan_overridden": false,
    "links": [
        {
            "href": "https://api.sandbox.paypal.com/v1/billing/subscriptions/I-YF498PUKF42J/cancel",
            "rel": "cancel",
            "method": "POST"
        },
        {
            "href": "https://api.sandbox.paypal.com/v1/billing/subscriptions/I-YF498PUKF42J",
            "rel": "edit",
            "method": "PATCH"
        },
        {
            "href": "https://api.sandbox.paypal.com/v1/billing/subscriptions/I-YF498PUKF42J",
            "rel": "self",
            "method": "GET"
        },
        {
            "href": "https://api.sandbox.paypal.com/v1/billing/subscriptions/I-YF498PUKF42J/suspend",
            "rel": "suspend",
            "method": "POST"
        },
        {
            "href": "https://api.sandbox.paypal.com/v1/billing/subscriptions/I-YF498PUKF42J/capture",
            "rel": "capture",
            "method": "POST"
        }
    ]
}

 */
