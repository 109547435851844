<template>
  <nav class="nav" v-show="$route.name !== 'Editor'">
    <div class="nav-item logo-wrapper">
      <img src="@/assets/fizzler-text.svg"/>
      <span class="tag clear" v-show="appLevel === 0">Free</span>
      <span class="tag clear" v-show="appLevel === 1">Full</span>
      <span class="tag red" v-show="appLevel === 2">Cloud</span>
      <!--      <span class="tag tag&#45;&#45;version clear">{{ version }}</span>-->
    </div>

    <!--    <pre>-->
    <!--      {{user}}-->
    <!--    </pre>-->

    <!--    <div class="nav-item">-->
    <!--      <h3 v-if="!system.app" style="flex: 2;text-align: center;">{{ $t(`nav.${$route.name}`) }}</h3>-->
    <!--    </div>-->

    <div class="nav-item">
      <router-link class="tag" style="padding: 5px 9px;" :to="'/options'" v-show="!user?.authenticated && !system.app">{{ $t("nav.betatester") }}!</router-link>
      <!--      <router-link class="tag" style="padding: 2.5px 9px;" :to="'/options'" v-show="!user.authenticated && !system.app">-->
      <!--        {{ $t("nav.get_app") }}!-->
      <!--      </router-link>-->
      <!--      <router-link class="tag" style="padding: 2.5px 9px;" :to="'/options'"-->
      <!--                   v-show="!user.authenticated && appLevel === 1 && system.app">{{ $t("nav.get_full_version") }}!-->
      <!--      </router-link>-->
      <router-link class="tag" style="padding: 2.5px 9px;" :to="'/options'"
                   v-show="!user?.authenticated && appLevel < 2 && system.app">{{ $t("nav.signup_for_cloud") }}
      </router-link>
    </div>

    <div class="nav-item" v-if="user && appLevel > 1">
      <span v-if="user" style="font-weight: normal;">&nbsp;Hi {{ user?.name }}! </span>
    </div>

    <div>
      <div class="nav-group">
        <router-link :class="['nav-item', $route.name === 'ProjectManager' ? 'active': '']" :to="'/'">
          <icon-home class="white"/>
        </router-link>

        <router-link :to="'/login'" :class="['nav-item', $route.name === 'Login' ? 'active': '']"
                     v-show="!user?.authenticated">
          <icon-user-circle class="white"/>
        </router-link>

        <router-link :class="['nav-item', $route.name === 'Login' ? 'active': '']" :to="'/login'"
                     v-show="user?.authenticated">
          <icon-user-circle class="white"/>
        </router-link>

        <!--      <a class="nav-item" :to="'/login'" v-show="user.authenticated" @click.prevent="logoutAction"><icon-user-circle class="white"/>LOGOUT</a>-->
        <!--v-show="$route.name !== 'ProjectManager'"-->

        <router-link :class="['nav-item', $route.name === 'About' ? 'active': '']" to="/about">
          <icon-book-f class="white"/>
        </router-link>

        <!--        d = {{appState.dirty}} | b = {{appState.busy}}-->

        <a class="nav-item" href="javascript:void(0)">
<!--          <select v-model="$i18n.locale">-->
<!--            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>-->
<!--          </select>-->
          <h3 v-touch:tap="()=>changeLocale()">{{ currentLocale?.toUpperCase() }}</h3>
        </a>

        <div class="nav-item nav-group">
          <icon-signal :class="offline ? 'red' : 'white'"/>
          <icon-refresh :class="[appState.syncing ? 'rotate': '', 'gray']"/>
        </div>


      </div>
    </div>

    <notifications/>
  </nav>
</template>
<script>

import { useI18n }       from "vue-i18n"
import { storeToRefs }   from "pinia";
import { useUserStore }  from "@/store/user";
import { appStateStore }       from "@/store/appState";
import { computed } from "vue";


export default {

  props: ['system', 'offline'],
  setup() {
    const i18n = useI18n()
    const userStore = useUserStore()
    const {user, appLevel} = storeToRefs(userStore)

    const appState = appStateStore()

    const changeLocale = () => {
      // Just toggle
      i18n.locale.value = (i18n.locale.value === 'en') ? 'de' : 'en'
      // userStore.$patch({user: {...user.value, ...{metadata: {locale: i18n.locale.value}}}})
      // update user
      userStore.setUserMeta({locale: i18n.locale.value})
    }
    const logoutAction = () => {
      userStore.logout()
    }

    return {
      appState,
      changeLocale,
      logoutAction,
      user,
      appLevel,
      version: process.env.PACKAGE_VERSION,
      currentLocale: computed(()=>i18n.locale.value)
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";

// Nav bar
nav.nav {
  position        : relative;
  padding         : 5px 15px;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  color           : white;
  background      : var(--background-dark);

  backdrop-filter : blur(4px);

  flex-direction  : column;
  @include tablet {
    position       : fixed;
    z-index        : 111;
    left           : 0;
    right          : 0;
    top            : 0;
    flex-direction : row;
  }

  // Items
  .nav-item {
    position       : relative;
    display        : inline-block;
    margin-right   : 10px;
    font-size      : 13px;
    letter-spacing : 1px;
    //height         : 22px;
    vertical-align : middle;
    padding        : 3px 6px;
    border-radius  : 8px;

    .jam {
      //height         : 24px;
      vertical-align : -0.1875em;
    }

    &.active {
      background : rgba(200, 200, 200, 0.3);
    }

    &.nav-group {
      background  : rgba(0, 0, 0, 0.1);
      height      : 28px;
      line-height : 24px;

      svg {
        height       : 12px;
        width        : 12px;
        margin-right : 5px;
      }
    }

  }

  .logo-wrapper {
    img {
      height : 40px;
    }

    .tag {
      position    : absolute;
      padding     : 1px 5px;
      font-size   : 9px;
      line-height : 13px;
      border      : 1px solid rgba(255, 255, 255, 0.5);
      background  : none;
      margin      : 4px -10px;

      &.red {
        color        : var(--highlight-color);
        border-color : var(--highlight-color);
      }

    }

    .tag--version {
      right : -8em;
    }

  }

}
</style>
