<template>
  <button :class="`button ${requestActive && 'busy'}`" :disabled="requestActive">
    <div>
      <!-- Busy -->
      <icon-save-indicator :spin="true" class="icon icon--busy"/>
      <!-- Preset icons -->
      <icon-user-circle v-show="!requestActive && icon === 'user'" class="icon"/>
      <icon-qrcode v-show="!requestActive && icon === 'qr'" class="icons"/>
      <slot class="content"/>
    </div>
  </button>
</template>
<script>
export default {
  name: "fiButton",
  props: ['requestActive', 'icon']
}
</script>
<style lang="scss" scoped>

// Overrides the .button||button scss in base.scss ~ L:221
button > div {
  display     : flex;
  align-items : center;
  padding     : 0.5em;
  }

.icon {
  fill         : white;
  width        : 18px;
  height       : 18px;
  margin-right : 5px;
  transition   : all 420ms;

  &--busy {
    display : none;
    opacity : 0;
    }
  }

// Disabled (spinner icon)
.busy {
  opacity : 0.7;

  .icon {
    display : initial;
    opacity : 1;
    }
  }

button:hover {
  .icon {
    fill : black;
    }
  }

.button.small {
  font-size   : 10px;
  font-weight : normal;
  padding     : 5px;
  }

button.tiny {
  font-size   : 8px;
  font-weight : normal;
  padding     : 2px;
  background  : transparent;
  // TODO: colors will adapt with vars. change this here
  //  color       : var(--background-color);
  color : var(--text-color);

  // Todo: change this to proper ::root mode
  .stage.dark & {
    color : var(--text-color);
    }
  }


</style>
