<template>
  <div class='container'>
    <div class='container-inner'>
      <img src="@/assets/logo.svg" style="width: 100px;"/>
      <div class='bars'>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Animation'
}
</script>
<style lang="scss" scoped>

$bg       : "transparent";
$color    : rgba(255, 255, 255, 0.5);

.container {
  position      : relative;
  width         : 10rem;
  height        : 10rem;
  background    : $bg;
  border-radius : 100%;
  margin        : 0 auto;
}

.container-inner {
  width         : 100%;
  height        : 100%;
  border-radius : 100%;
  overflow      : hidden;

  img {
    position : absolute;
    width    : 32%;
    height   : 32%;
    top      : 34%;
    left     : 19%;
  }
}

.bars {
  position      : relative;
  width         : 100%;
  height        : 100%;
  border-radius : 100%;
  overflow      : hidden;
}

.bar {
  width                    : 1px;
  margin-left              : -1px;
  height                   : 50%;
  position                 : absolute;
  top                      : 50%;
  left                     : 50%;
  -webkit-transform-origin : 50% 0;
  -moz-transform-origin    : 50% 0;
  transform-origin         : 50% 0;
}

.bar:after {
  content    : "";
  position   : absolute;
  top        : 30%;
  left       : 0;
  width      : 1px;
  height     : 70%;
  //background : $color;
  //filter     : blur(2px);
  background : rgba(255, 255, 255, 0.3);
  box-shadow : 0px 0px 3px rgba(255, 255, 255, 0.3);
}

$elements : 121;
$steps    : 3;
@for $i from 0 to $elements {
  .bar:nth-child(#{$i}) {
    -webkit-transform : rotate($steps * $i + deg);
    -moz-transform    : rotate($steps * $i + deg);
    transform         : rotate($steps * $i + deg);
  }
  .bar:nth-child(#{$i}):after {
    -webkit-animation       : animation+$i 2s infinite linear alternate;
    -webkit-animation-delay : -$i*0.1 + s;
    -moz-animation          : animation+$i 2s infinite linear alternate;
    -moz-animation-delay    : -$i*0.1 + s;
    animation               : animation+$i 2s infinite linear alternate;
    animation-delay         : -$i*0.1 + s;
  }
  @-webkit-keyframes animation#{$i} {
    0% {
      height : 10 + random(30) * 1%;
    }
    50% {
      height : 10 + random(60) * 1%;
    }
    100% {
      height : 10 + random(30) * 1%;
    }
  }
  @-moz-keyframes animation#{$i} {
    0% {
      height : 10 + random(30) * 1%;
    }
    50% {
      height : 10 + random(60) * 1%;
    }
    100% {
      height : 10 + random(30) * 1%;
    }
  }
  @keyframes animation#{$i} {
    0% {
      height : 10 + random(30) * 1%;
    }
    50% {
      height : 10 + random(60) * 1%;
    }
    100% {
      height : 10 + random(30) * 1%;
    }
  }
}

</style>
