<template>
  <div id="helper-ovl">
    <div v-if="current && helpEnabled">
      <div ref="item" :class="`item is-one arrow-${current.arrow}`" :style="`left:${current.x}px;top:${current.y}px;`">
        <icon-close class="item--close white" v-touch:tap="quitHelp"/>
        <!--      {{current.id + 1}}/15-->
        <p v-html="$t(current.text)"/>
        <div class="dot" v-if="current.dot">
          <div class="ringring"></div>
          <div class="circle"></div>
        </div>

        <!--        <div v-if="current.lock" :style="`position:fixed;background:red;width:4px;height:4px;left:${current.lock.x}px;top:${current.lock.y}px;`"></div>-->
        <!--        <div v-if="current.lock" :style="`position:fixed;background:red;width:4px;height:4px;left:${current.lock.x1}px;top:${current.lock.y1}px;`"></div>-->

      </div>
    </div>

    <Modal v-if="showConfirm" class="is-one">
      <strong>End help?</strong>
      <p>So you know it all, aye? Well, if you want to stop, confirm. You can always get back into help by clicking that little button.</p>
      <div class="controls align-center">
        <fi-button class="button small" v-touch:tap="onUserStubborn" style="margin-right:2rem;">No more help!</fi-button>
        <fi-button class="button small" v-touch:tap="onUserMindUpdate">Cancel</fi-button>
      </div>
    </Modal>
  </div>
</template>
<script>
/* eslint-disable */
import { computed, onBeforeMount, ref } from "vue";
import { appStateStore }                from "@/store/appState";
import { useUserStore }                 from "@/store/user";
import { storeToRefs }                  from "pinia";
import Modal                            from "@/components/Modal"
import FiButton                         from "@/components/fiButton";


export default {
  name: "HelperOverlay",
  components: {FiButton, Modal},
  props: ['user'],
  setup() {

    const store = appStateStore()
    const {currentHelpItem, help, helpEnabled, helpFinished} = storeToRefs(store)
    const userStore = useUserStore()
    const {user} = storeToRefs(userStore)

    const showConfirm = ref(false)

    /**
     * Get current help item for display and adjust position
     * @return {{x: number, y: number, text: string}}
     */
    const current = computed(() => {
      const hi = {...currentHelpItem.value}
      if (!Object.keys(hi).length)
        return null
      return hi
    })


    // DEV:
    // Set next help item or by params (public)
    const nextHelp = (params) => {
      help.value.pointer++
      // Finished?
      if (helpFinished.value) helpEnabled.value = false
    }

    /**
     * Yeah, really?
     */
    const quitHelp = () => {
      if (!helpFinished.value)
        showConfirm.value = true
      else {
        helpEnabled.value = false
        userStore.setUserMeta({helpEnabled: false}) // flag as done
      }
    }

    const onUserMindUpdate = () => {
      showConfirm.value = false
    }

    const onUserStubborn = () => {
      showConfirm.value = false
      helpEnabled.value = false
      userStore.setUserMeta({helpEnabled: false}) // flag as done
    }

    onBeforeMount(() => {
      helpEnabled.value = user.value?.metadata?.helpEnabled
    })

    // ------------------


    return {
      help,
      helpEnabled,
      current,
      // toggleHelp,
      quitHelp,
      nextHelp,
      showConfirm,
      onUserMindUpdate,
      onUserStubborn,
      prevHelp: () => {
        help.value.pointer--
      }
    }
  }

}
</script>
<style lang="scss" scoped>
$bg      : rgba(18, 38, 70, 1);
$dotSize : 20px;

#helper-ovl {
  position : absolute;
  z-index  : 250;
  top      : 0;
  left     : 0;
  width    : 1px;
  height   : 1px;
  overflow : visible;

  .item {
    position      : absolute;
    width         : 240px;
    height        : auto; //132px;
    padding       : 15px;
    border-radius : 3px;
    color         : white;
    background    : $bg;
    //backdrop-filter : blur(4px);
    box-shadow    : rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    font-size     : 13.5px;

    p {
      margin : 10px 0 0 0;
    }

    // arrows?
    &.arrow-up::before {
      content       : "";
      position      : absolute;
      top           : -10px;
      left          : calc(50% - 10px);
      width         : 0;
      height        : 0;
      border-left   : 10px solid transparent;
      border-right  : 10px solid transparent;

      border-bottom : 10px solid $bg;
    }

    &.arrow-down::before {
      content      : " ";
      position     : absolute;
      bottom       : -10px;
      left         : 60%;
      width        : 0;
      height       : 0;
      border-left  : 10px solid transparent;
      border-right : 10px solid transparent;

      border-top   : 10px solid $bg;
    }

    &.arrow-right::before {
      content       : "";
      position      : absolute;
      right         : -10px;
      width         : 0;
      height        : 0;
      border-top    : 10px solid transparent;
      border-bottom : 10px solid transparent;

      border-left   : 10px solid $bg;
    }

    &.arrow-left::before {
      content       : "";
      position      : absolute;
      left          : -10px;
      width         : 0;
      height        : 0;
      border-top    : 10px solid transparent;
      border-bottom : 10px solid transparent;

      border-right  : 10px solid $bg;
    }

    // Dot position, relative to arrow
    &.arrow-up .dot {
      top  : -60%;
      left : calc(50% - 10px);
    }

    &.arrow-down .dot {
      margin-top : 40%;
      left       : 60%;
    }

    &.arrow-left .dot {
      top  : 12.5%;
      left : -50%;
    }

    &.arrow-right .dot {
      top   : 12.5%;
      right : -40%;
    }

  }

  .dot {
    position       : absolute;
    pointer-events : none;

    .circle {
      width            : 15px;
      height           : 15px;
      background-color : red;
      border-radius    : 50%;
      position         : absolute;
      top              : 5px;
      left             : 5px;
    }

    .ringring {
      border                            : 3px solid red;
      border-radius                     : 50%;
      height                            : 25px;
      width                             : 25px;
      position                          : absolute;
      left                              : 0;
      top                               : 0;
      -webkit-animation                 : pulsate 1s ease-out;
      -webkit-animation-iteration-count : infinite;
      opacity                           : 0.0
    }

    @-webkit-keyframes pulsate {
      0% {
        -webkit-transform : scale(0.1, 0.1);
        opacity           : 0.0;
      }
      50% {
        opacity : 1.0;
      }
      100% {
        -webkit-transform : scale(1.2, 1.2);
        opacity           : 0.0;
      }
    }

  }

  .item--close {
    position : absolute;
    top      : 2px;
    right    : 2px;
  }
}


</style>
