/* eslint-disable */
import PouchDBAuthentication                                     from 'pouchdb-authentication';
import PouchDB                                                   from 'pouchdb';
import find                                                      from 'pouchdb-find';
import axios                                                     from 'axios';
import config                                                    from "@/config";
import { uuid }                                                  from "vue-uuid";
import { Capacitor }                                             from "@capacitor/core";
import { cipher, getLocalUserPrefs, getSalt, setLocalUserPrefs } from "@/utils";
import { useUserStore }                                          from "@/store/user";
import { storeToRefs }                                           from "pinia";
import { userModel }                                             from "@/models";
import { appStateStore }                                         from "@/store/appState";


// PouchDB --------------------
PouchDB
.plugin(find)
.plugin(PouchDBAuthentication)


// Local databasez
let baseDB = new PouchDB('fizzler', {auto_compaction: true})
// API URL (service / a PHP api actually)
const serviceUrl = config.serviceUrl
// Remote Database [called API unfortunately]
const dbUrl = config.dbUrl
// Remote DB MAIN
let remoteDb = new PouchDB(dbUrl, {skip_setup: true, auto_compaction: true, fetch: (url, opts) => fetch(url, {...opts, credentials: 'include', withCredentials: true})})

// Axios ----------------------
axios.defaults.withCredentials = true
const api = axios.create({
	baseURL: serviceUrl,
	timeout: 3000,
	defaultInterceptors: true,
})


// -------------------------------------------
// User
// All we need the user data for is to sync with the cloud
// For syncing we need a user-db [per-user] and make sure we have that
//
// -------------------------------------------

/*
READ:
1) https://github.com/pouchdb-community/pouchdb-authentication/blob/master/docs/api.md#dbsignupusername-password--options--callback
2) https://github.com/apache/couchdb/issues/1019
	https://stackoverflow.com/questions/62074186/user-cannot-access-own-document-in-users-in-couchdb
	https://github.com/apache/couchdb/issues/1019 (!)
 */

const _deleteLocalData = () => {
	const appState = appStateStore()
	const {system} = storeToRefs(appState)
	// We have logged out. Now delete all local docs, if this is not an app
	console.warn('Clearing data', Capacitor.getPlatform(), system.value)
	// Apps (level 2) keep their data!
	if (Capacitor.getPlatform() === 'web' && !system.value.app) {
		baseDB.destroy().then(function () {
			baseDB = new PouchDB('fizzler', {revs_limit: 1, auto_compaction: true}) // reinit right here.
		}).catch(function (err) {
			console.log(err);
		});
	}
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// User
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const getLocalUser = async () => {
	return {...userModel, ...{name: getLocalUserPrefs()?.name, password: getLocalUserPrefs()?.password, authenticated: false, metadata: getLocalUserPrefs()}}
}

/**
 * Get current user from session
 * Todo: replace with : https://github.com/perfood/couch-auth#database-security
 * @return {*}
 */
export const apiGetUserFromSession = () => {
	return remoteDb.getSession()
	.then(async response => {
		console.log('Session check for ', response.userCtx.name)
		if (!response.userCtx.name) {
			// If there are user credentials stored, log the user in again. Quietly. App only!
			const uc = await getLocalUser()
			if (!uc || (uc && uc.logout_forced)) {
				_deleteLocalData()  // clear what's in the project list
				console.log('Session: logged out')
				return null
			} else if (uc) {
				// new PouchDB(`${dbUrl}/userdb-${_encode(uc.name)}`,
				// Try to relogin, but this would mean we need to store the user pass - not viable for browser app!
				console.log('Session: retry login for ', uc.name)
				return apiLogin(uc)
			}
		}
		// Return session | get user
		return response.userCtx.name !== null
			? {user: await remoteDb.getUser(response.userCtx.name), e: null}
			: {user: null, e: 'hmmm'}
	}).catch(e => {
		console.warn(e)
		// _deleteLocalData()
		return {user: null, error: e}
	})

}

/**
 * Cloud User login
 * @param credentials
 * @return {void | Promise<PouchDB.Authentication.LoginResponse>}
 */
export const apiLogin = async (credentials) => {
	const {name, password} = credentials
	// This lib is outdated. -> https://github.com/pouchdb-community/pouchdb-authentication/blob/master/src/authentication.js
	// https://stackoverflow.com/questions/72045698/capacitor-ios-using-cookie-based-auth
	return remoteDb.logIn(name, password).then(async r => {
		if (r.ok && r.name) {
			const rUser = await remoteDb.getUser(r.name)
			// save credentials (todo: app only)
			let pCipher = cipher(getSalt())
			let hash = pCipher(password)
			setLocalUserPrefs({name: name, password: hash})
			return {user: rUser, error: null}
		} else {
			_deleteLocalData()
			return getLocalUser()
		}
	}).catch(e => {
		console.warn(e)
		// Delete all local data
		// _deleteLocalData()
		return {error: e, user: null}
	})
}

/**
 * Sign a new user up. Make sure he has paid first.
 * @param credentials
 * @return {Promise<void | PouchDB.Authentication.LoginResponse>}
 */
export const apiSignUp = (credentials) => {
	// Using the server api to do this.
	return api.post(`${serviceUrl}/signup`, credentials)
}

/**
 * Logout
 * @return {void | Promise}
 */
export const apiLogout = async () => {
	const res = await remoteDb.logOut()
	if (res.ok) {
		// Update local user (forced logout)
		setLocalUserPrefs({logout_forced: true})
		_deleteLocalData()
	}
	return res
}

/**
 * Update user metadata local or live
 * @param metadata
 * @returns {Promise<*>}
 */
export const apiSetUserMeta = async (metadata) => {
	const store = useUserStore()
	const {user} = storeToRefs(store)

	// Offline or not logged in, update local preferences only
	if (!user.value || !user.value.authenticated) {
		console.info('NO USER')
		setLocalUserPrefs(metadata)
		// return {...defaultUser, ...metadata}
		return true
	}

	// If user exists (remote), then update directly
	const uname = user.value.name
	remoteDb.putUser(uname, {
		// Don't confuse the METADATA prop for a node within the user object!
		metadata: {metadata: {...user.value.metadata, ...metadata}}
	}).then(async () => {
		return true //await remoteDb.getUser(uname)
	}).catch(e => console.warn(e))
	// })
}


/**
 * Sync local and remote
 * TODO: Issue on bulk_get => https://dev.to/craftzdog/improve-pouchdb-s-initial-sync-with-couchdb-3x-faster-14m4
 * @param user
 */
export const sync = (username) => {
	console.warn('SYNCING CALLED', username)
	const url = `${dbUrl}/userdb-${_encode(username)}`;
	const opts = {live: true, retry: true, include_docs: false}; // Todo: include_docs: true --- then we won't need to pull projects again.
	return baseDB.sync(url, opts).on('complete', ()=> {
		console.warn(':::: EYEYEYE');

	})
}

// do one way, one-off sync from the server until completion
export const pullFromLive = (username) => {
	const url = `${dbUrl}/userdb-${_encode(username)}`;
	return baseDB.replicate.from(url) // https://pouchdb.com/api.html#sync
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------- Passwort reset methods
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

/**
 * Reset passwort once the user has the right code
 * @param email
 */
export const apiResetPass = (email) => {
	return api.post(`${serviceUrl}/resetpass`, email)
}

/**
 * Execute a password change
 * @param username
 * @param password
 */
export const apiResetPassExecute = (payload) => {
	// Let the server handle this
	const {username, password, code} = payload
	return api.post(`${serviceUrl}/resetpassexecute`, {username: username, password: password, code: code})
}

/**
 * Get user by name and pw-reset-code
 * @param username
 * @param code
 * @return {{}}
 */
export const getUserByNameAndCode = async (username, code) => {
	// Let the server check
	return api.post(`${serviceUrl}/checkuserbycode`, {username: username, code: code})
}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Projects Data
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

/**
 * Return all projects for user
 * @param user
 * @return {*}
 */
export const apiGetProjects = async () => {
	/*
		A) User has full app and later upgrades to cloud
		B) Multiple users will login to cloud in the same app
		C) User is in browser and different ones want to login
		 */
	// return remoteUserDb ? remoteUserDb.allDocs({_attachments: true}) : baseDB.allDocs({_attachments: true})
	// We use the localDB and only sync will execute
	return baseDB.allDocs({
		include_docs: true,
		attachments: false,
		conflicts: true
	})
}

/**
 * Get project by id
 * @param id
 * @return {Promise<*>}
 */
export const apiGetProject = async (id, rev = null) => {
	//977-f9b7d930ac510cb9c2d289a7c1ab29fa
	let project = null
	try {
		project = rev ?  await baseDB.get(id, {rev: rev, conflicts: false}) : await baseDB.get(id, {conflicts: false})
	} catch (e) {
		console.warn('Error loading project ', e)
	}
	return project
}

/**
 * Delete a project
 * @param id
 * @return {Promise<*>}
 */
export const apiDeleteProject = async (id) => {
	return baseDB.get(id, {conflicts: true})
	.then(async doc =>  {
		// doc._deleted = true
		// return baseDB.put(doc)
		// ----- Handle conflicts. We just delete them !!!!
		// ----- Even though PouchDB docs say that _delete: true and a new rev would suffice, this seems not so when conflicts exist. The stack will get smaller by 1 only
		await apiRemoveConflicts(id)
		return baseDB.remove(doc)
	})
}

export const apiRemoveConflicts = async (id) => {
	return baseDB.get(id, {conflicts: true})
	.then((doc) => {
		let ps = []
		if (doc._conflicts) {
			doc._conflicts.map(rev => {
				ps.push(baseDB.remove(doc._id, rev))
			})
		}
		// wait for deletion
		return Promise.all(ps)
	})
}

/**
 * Create or update a project
 * @param user
 * @param project
 * @return {Promise<*>}
 */
export const apiSaveProject = async (project, create = false) => {
	let r = null
	const userStore = useUserStore()
	const {user} = storeToRefs(userStore)

	// Update existing
	if (!create) {
		return baseDB.get(project._id, {conflicts: false})
		.then(async (doc) => {
			// console.info('ApiS', doc._id)
			delete project._rev  // Doc ref will be the current one.
			// ----- Update user ['stranger' - if it was created whilst not logged in]
			project.author = user.value.name
			// ----- Update project
			r = baseDB.put({...doc, ...project})
			.then(r => {
				// todo: could resolve conflicts here.
				return true
			})
			.catch((err) => {
				if (err.name === 'conflict') {
					console.warn('Conflict of docs detected.', err)
				} else
					console.warn(err)
				return null
			})
		}).catch(async (e) => {
			console.warn('Project update failed', e)
			// Usually we could create here. But this may lead to duplicates if _rev gets lost
		})
	} else {
		// Create new.
		r = await baseDB.put({...project, ...{_id: uuid.v4()}}) // create
		.catch((err) => {
			if (err.name === 'conflict') {
				console.warn('Conflict of docs detected.', err)
			} else
				console.warn(err)
		})
		// Clean conflicts
// 		if (r.ok) {
console.log(r)
// 		}

		return (r.ok) ? {...project, ...{_id: r.id}} : null
	}
}

/**
 * Return an attachment
 * The ID is combined from <type>_<node.id>
 * @param att {docId: projectId, attId: }
 * @returns {*}
 */
export const apiGetAttachment = async (att = {docId: null, attId: null}, convertToJson = false) => {
	return new Promise(async resolve => {
		let r = null
		try {
			r = await baseDB.getAttachment(att.docId, att.attId)
		} catch (e) {
			r = null
		}

		// Convert to JSON object when requested
		if (r && convertToJson) {
			const fr = new FileReader();
			fr.onload = (e) => {
				r = e.target.result && typeof e.target.result !== "undefined" ? JSON.parse(e.target.result) : []
				resolve(r)
			};
			fr.readAsText(r);
		} else {
			// Return whatever we got.
			resolve(r)
		}
	})

}

/**
 * Save attachment
 *
 * @param att JSON {docId: id of project, attId: <type>_<node.id>, blob: audio|sketch blob}
 * @returns {Promise<*>}
 */
export const apiSaveAttachment = async (att = {docId: null, attId: null, blob: null}) => {
	const {docId, attId, blob} = att
	if (!blob) return
	let doc = await baseDB.get(docId)
	try {
		return baseDB.putAttachment(docId, attId, doc._rev, blob, blob.type)
	} catch (e) {
		console.warn('Putting attachment failed ', e)
		return Promise.resolve(null)
	}

}

/**
 * Delete an attachment
 * @param att
 * @returns {Promise<unknown>}
 */
export const apiDeleteAttachment = async (att = {docId: null, attId: null, rev: null}) => {
	let {docId, attId, rev} = att
	try {
		return baseDB.removeAttachment(docId, attId, rev)
	} catch (e) {
		console.warn('Asset delete failed ', e)
		return Promise.resolve(null)
	}
}

/**
 * Just emailing logic [newsletter etc.]
 * @param payload
 * @return {Promise<AxiosResponse<any>>}
 */
export const joinBeta = (payload) => {
	return axios.post(`${serviceUrl}/joinbeta`, payload)
}

const _encode = (str) => {
	return str ? str.split("").map(function (c) {
		return ("0" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join("") : ''
}
