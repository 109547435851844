/* eslint-disable */
// Todo: use https://www.npmjs.com/package/undo-stacker
import { appStateStore } from "./appState";

function clone(obj) {
	obj = obj?.project
	if (!obj) return null
	try {
		return JSON.parse(JSON.stringify(obj));
	} catch (error) {
		return obj;
	}
}

export default function createUndoRedoPlugin(options) {
	// options
	const {store} = options;
	// const cloned = () => clone(store.$state);
	const setStore = (state) => store.$patch({project: state});
	const stack = [];
	let pluginAction = false;

	const appState = appStateStore()

	store.undo = () => {
		pluginAction = true;

		stack.pop();
		const current = stack[stack.length - 1];
		setStore(current);
	};
	store.redo = () => {
		pluginAction = true;

		const current = stack[stack.length - 1];
		setStore(current);
	};
	store.canUndo = () => {
		// console.info('stack ---> ', stack.length)
		return stack.length > 0
	};

	store.setClean = () => {
		stack.splice(0, stack.length - 1)
		appState.dirty = false
	}

	// if (store.$id === 'project')
	// 	store.$onAction = (() => {
	// 		// Only project
	// 		console.warn(store)
	// 		if (pluginAction) {
	// 			pluginAction = false;
	// 			return;
	// 		}
	// 		appState.dirty = true
	//
	// 		console.log(store.$id)
	// 	})

	// Subscribe to all store changes
	if (store.$id === 'project')
		store.$subscribe(() => {
			if (pluginAction) {
				pluginAction = false;
				return;
			}
			appState.dirty = true
			// console.log(store.undoable)
			// if (!store.undoable) return
			const _cloned = clone(store.$state)
			// console.info('stack ---> ', stack.length, stack)
			if (_cloned !== null)
				stack.push(_cloned);
		});
}
