import { defineStore, storeToRefs } from 'pinia'
import { apiSaveProject }           from "@/store/api";
import { stageModel }               from "@/models";
import { apiGetProject }            from "./api";
import { useUserStore }             from "./user";
import { Capacitor }                from "@capacitor/core";

/*eslint-disable*/

const migrate = (project) => {
	if (project?.version > 1 || !project?.stages) return project

	try {
		project.nodes = project.stages.reduce((a, {nodes, id}) => [
			...a,
			...nodes.map(e => ({...e, stageId: id}))
		], [])
		project.connectors = project.stages.reduce((a, {connectors, id}) => [
			...a,
			...connectors.map(e => ({...e, stageId: id}))
		], [])
		project.stages = project.stages.map(s => {
			return {
				id: s.id,
				background: s.background,
				style: s.style,
				position: s.position,
				order: s.order
			}
		})
		project.version = 2
	} catch (e) {
		console.warn('Migration ', e)
	}


	return project
}

export const useProjectStore = defineStore('project', {
	state: () => {
		return {
			project: null,
			dirty: false,
			undoable: false // if set, then add to undo stack
		}
	},

	actions: {

		async getProject(id) {
			this.undoable = false
			const p = await apiGetProject(id)
			this.project = migrate(p)
			return true
		},

		/**
		 * Update a project. This concerns the title and order
		 * @param payload
		 * @returns {Promise<void>}
		 */
		async updateProject(payload) {
			this.undoable = false
			this.$patch({project: payload})
			return this.project
		},


		/**
		 * Change the stage in view or create a new
		 * @param payload JSON index = activeStage (0,1) / node = currentParentNode / preset (stage background, options, etc)
		 *
		 */
		async switchStage(payload = {currentParentNodePointer: 0, node: {id: 0, content: ''}, preset: null}) {
			this.undoable = false
			const {currentParentNodePointer, node, preset} = payload
			let isNew = false
			// Check if this is an existing stage || node.id.toString === '0' <- NEVER create
			const foundStage = this.project.stages.find(s => s?.id.toString() === node?.id.toString())
			if (foundStage) {
				this.project.currentParentNodes[currentParentNodePointer] = foundStage                 // set to current
				this.project.currentParentNodes[currentParentNodePointer] = {id: node.id, content: node.content}
			} else if (node.id.toString() !== '0') {
				// create!
				const _stage = {...stageModel, ...{id: node.id}, ...preset}   // mixin the node id && the preset (styles) && make sure we have a proper model.
				this.project.stages.push(_stage)
				this.project.currentParentNodes[currentParentNodePointer] = {id: node.id, content: node.content} // update the current or split stage
				// Update the stage
				this.project.currentParentNodes[currentParentNodePointer] = _stage
				isNew = true
			}
			return {new: isNew}
		},

		/**
		 * Stage data update. Either options or position
		 * @param payload JSON all || part of stageModel
		 * @returns {Promise<void>}
		 */
		async updateStage(payload) {
			this.dirty = true
			this.undoable = true
			const index = this.project.stages.findIndex((item) => {
				return item?.id.toString() === payload?.id.toString();
			});

			// Push to elementCollection, if not exists
			if (index === -1) {
				this.project.stages.push(payload)
			}

			// Update element within elementCollection
			this.project.stages = [
				...this.project.stages.slice(0, index),
				payload,
				...this.project.stages.slice(index + 1)
			]
			// await this.putToDb()    // save instantly?
		},

		// Simply move a stage (give it another id or duplicate) to a new node (parent)
		// Used for node copying - this will copy all subs to a new node
		transferStage(node, toStageId) {
			this.dirty = true
			this.undoable = true
			// Insert as new with id of parent node
			// const stages = this.project.stages.reduce((a, {nodes}) => [...a, ...nodes], [])
			// Update all children nodes with new ids and their stages:
			let childNodes = [], childConn = []
			const recurse = (nodeId) => {
				const stageofNode = this.project.stages.find(s => s.id === nodeId)
				if (stageofNode) {
					stageofNode.id = nodeId // update to new Id.
					// Addd nodes and connectors
					childNodes.push(stageofNode.nodes)
					childConn.push(stageofNode.connectors)
					stageofNode.nodes.map(n => {
						recurse(n.id)
					})
				}
			}
			recurse(node.id)
			const newStage = this.project.stages.find(s => s.id === toStageId)
			if (newStage) {
				newStage.nodes.concat(childNodes)
				this.project.stages.push(newStage)
			}

		},

		/**
		 * Transfer a node and all its children to a new stage
		 * @param node JSON complete nodeModel w. data.
		 * @param toStageId
		 */
		transferNode(node, toStageId) {
			this.dirty = true
			this.undoable = true
			// console.warn('transferNode ', node, toStageId)
			// Remove all connectors from originating stage
			// this.removeConnector({fromId: node.id.toString(), toId: node.id.toString()})
			this.project.connectors = this.project.connectors.filter(c => c.from !== node.id && c.to !== node.id)
			// Move from A to B
			this.project.nodes.map(n => {
				if (n.id === node.id) {
					n.stageId = toStageId
					n.position = node.position
					n.time = Date.now()
				}
			})
		},

		/**
		 * Add a new node
		 * @param payload JSON nodeModel
		 * @returns {Promise<boolean>}
		 */
		async addNode(payload) {
			this.dirty = true
			this.undoable = true
			this.project.nodes.push(payload)
			return true
		},

		/**
		 * Update a node's content / position
		 * @param payload JSON complete node object / nodeModel
		 * @param isAttachment
		 * @param stageId String The ID of the stage to put the node into. If set, it will override the automatic active stage in store (e.g. copy a node or update content of a non-active node)
		 * @returns {Promise<void>}
		 */
		async updateNode(payload, attachment = null) {
			this.dirty = true
			this.undoable = false
			this.project.stages.find(s => s.id === payload.stageId) // must be in node

			// ReCheck
			if (!payload || !payload.type || typeof payload.type === "undefined") return null

			// Add time
			payload = {...payload, ...{time: Date.now()}}

			// Exists?
			const index = this.project.nodes.findIndex((item) => {
				return item?.id.toString() === payload?.id.toString();
			});

			// Push to elementCollection, if not exists
			if (index === -1) {
				this.project.nodes.push(payload)
			}

			// Update element within elementCollection
			if (index !== -1)
				this.project.nodes = [
					...this.project.nodes.slice(0, index),
					payload,
					...this.project.nodes.slice(index + 1)
				]


			// ---------- Attachments
			// Make sure this is only executed, when an update to DATA / BLOB has occurred.
			if (!this.project._attachments) this.project._attachments = {}
			if (attachment && payload.type === 'audio') {
				// Send to DB instantly
				const att = {
					docId: this._id,
					attId: `audio_${payload.id}`,
					blob: attachment
				}
				// console.log('///AUDIO ', att)
				// Directly push to project
				this.project._attachments[att.attId] = ({content_type: att.blob.type, data: att.blob})
			}
			if ((attachment) && payload.type === 'sketch') {
				// Send to DB instantly
				// console.log('||||| SKETCH ||||', attachment)
				const att = {
					docId: this._id,
					attId: `sketch_${payload.id}`,
					blob: new Blob([JSON.stringify(attachment)], {type: 'application/json'})
				}
				this.project._attachments[att.attId] = ({content_type: 'application/json', data: att.blob})
			}

			// Save
			// this.putToDb()  // save
			return payload
		},

		/**
		 * Delete a node and all associated
		 * @param payload
		 * @returns {Promise<boolean>}
		 */
		async deleteNode(payload) {
			this.dirty = true
			this.undoable = true
			// RECURSIVELY Remove child nodes from all other stages
			const recurse = (nodeId) => {
				let stageofNode = this.project.stages.find(s => s.id.toString() === nodeId.toString())
				if (stageofNode) {
					this.project.nodes = this.project.nodes.filter(n => {
						if (n.stageId.toString() === stageofNode.id.toString()) {
							// Attachments
							delete this.project._attachments[n.type + '_' + n.id] // Bamm. Right away with this crap.
							// Connectors
							this.project.connectors = this.project.connectors.filter(conn => !(n.id === conn.from || n.id === conn.to))
							recurse(n.id)
						} else {
							return n   // keep
						}
					})
					this.project.stages = this.project.stages.filter(ss => ss.id !== stageofNode.id)   // remove stage too
				}
			}
			recurse(payload.id)

			// Remove the current node
			this.project.nodes = this.project.nodes.filter(n => n.id !== payload.id)
			// Connectors
			this.project.connectors = this.project.connectors.filter(conn => !(payload.id === conn.from || payload.id === conn.to))

			// remove stage too
			this.project.stages = this.project.stages.filter(ss => ss.id !== payload.id)    //
			// Attachment
			delete this.project?._attachments[payload.type + '_' + payload.id] // Bamm. Right away with this crap.

			// this.putToDb()

			return true
		},

		/**
		 * Add a connector between 2 nodes
		 * @param payload
		 */
		addConnector(payload) {
			this.dirty = true
			this.undoable = true
			this.project.connectors.push({...payload, ...{time: Date.now()}})
		},

		/**
		 * Remove all connectors from and to a specified node
		 * @param payload
		 */
		removeConnector(payload) {
			this.dirty = true
			this.undoable = true
			const {fromId, toId} = payload;
			this.project.connectors = this.project.connectors.filter(
				conn =>
					!(
						(fromId === conn.from && toId === conn.to) ||
						(fromId === conn.to && toId === conn.from)
					)
			)
		},

		/**
		 * Update a single connector NIY
		 * @param payload
		 */
		updateConnector(payload, stagePos = 0) {
			this.dirty = true
			this.undoable = false
			const index = this.project.connectors.findIndex((item) => {
				return item.to.toString() === payload.to.toString() && item.from.toString() === payload.from.toString()
			})

			payload = {...payload, ...{time: Date.now()}}   // refresh time key

			// Push to elementCollection, if not exists
			if (index === -1) {
				this.project.connectors.push(payload)
				return
			}

			// Update element within elementCollection
			this.project.connectors = [
				...this.project.connectors.slice(0, index),
				payload,
				...this.project.connectors.slice(index + 1)
			]
		},

		/**
		 * Update all connectors of a stage
		 * @param options
		 * @param stageId
		 */
		updateConnectorOptions(options, stageId) {
			this.undoable = false
			this.project.connectors.map(c => {
				if (c.stageId === stageId) {
					c.options = options
					c.time = Date.now()
				}
			})
		},

		/**
		 * Syncronize Store with DB
		 * This will only save the current project!
		 * @return {Promise<unknown>}
		 */
		async putToDb() {
			const userStore = useUserStore()
			const {user} = storeToRefs(userStore)
			this.setClean()
			this.undoable = false
			// Auto-update user into project. This is unsed when a logged in user (cloud) marks local docs ('stranger') to the new username
			this.project.author = user.value.name
			// Udpate device
			this.project.device = Capacitor.getPlatform()
			await apiSaveProject({...this.project, ...{time: Date.now()}})
			this.dirty = false
			return true
		}

	},

	getters: {
		// currentStage: (state) => state.project.stages.find(s=>s.id === state.project.currentParentNodePointer),
		// currentParentNode: (state) => state.project?.nodes.find(n=>n.id === state.project?.currentParentNodes[0]?.id),
		leftStage: (state) => state.project?.stages.find(s => s.id.toString() === state.project?.currentParentNodes[0]?.id.toString()),
		rightStage: (state) => state.project?.stages.find(s => s.id.toString() === state.project?.currentParentNodes[1]?.id.toString())

	}
})
