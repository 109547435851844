import { Capacitor }                from "@capacitor/core";
import { helpStates }               from "@/store/help";
import { useProjectStore }          from "@/store/project";
import { defineStore, storeToRefs } from "pinia";
import config                       from "@/config";

// App State
const defaultContextItems = ['default', 'h1', 'h2', 'sketch', 'audio', 'image']
const defaultState = {
	packageVersion: process.env.PACKAGE_VERSION || '0',
	syncing: false,
	wait: false,            // means, we block the ui
	busy: false,            // generally busy. set by other stores.
	dirty: false,
	requestActive: false,
	// ---- Help
	helpEnabled: true,
	// This points to a help item (json) and position of the content bubble
	help: {
		pointer: 0,
		x: 0,
		y: 0
	},
	// Help state, affecting the usability of items
	stagePanEnabled: true,
	nodeNavEnabled: true,
	contextItemsEnabled: defaultContextItems,       // Items in context menu can be disabled
	highlightedContextItems: [],                    // Just highlight items in the context menu
	clickRestrained: null
}

export const appStateStore = defineStore('appstate', {
	state: () => {
		return {...defaultState}
	},

	actions: {
		resetHelpState() {
			this.helpEnabled = false
			this.stagePanEnabled = true
			this.nodeNavEnabled = true
			this.contextItemsEnabled = defaultContextItems
			this.highlightedContextItems = []
		}
	},

	getters: {
		system: () => {
			return {
				...process.env, ...{
					isDev: process.env.NODE_ENV === 'development',
					app: Capacitor.getPlatform() !== 'web' || process.env.IS_ELECTRON,
					platform: Capacitor.getPlatform(),
					api: config.apiUrl,
					svc: config.serviceUrl
				}
			}
		},
		currentHelpItem: (state) => {
			if (!state.helpEnabled) return null
			let hi = helpStates.find(i => i.id === state.help.pointer)
			// mixin custom positions if set in [help] value
			const projectStore = useProjectStore()
			const {leftStage} = storeToRefs(projectStore)

			// Read settings from object and disable certain features
			if (hi.s) {
				state.$patch(hi.s)
			} else {
				state.helpEnabled = false
				state.stagePanEnabled = true
				state.nodeNavEnabled = true
				state.contextItemsEnabled = defaultContextItems
				state.highlightedContextItems = []
			}

			// Adjust custom positions
			if (state.help.x !== 0 || state.help.y !== 0) {
				hi = {
					...hi, ...{
						// 'absolute' prop means that the stage position doesn't matter
						x: !state.help.absolute ? state.help.x + leftStage.value.position.x : state.help.x,
						y: !state.help.absolute ? state.help.y + leftStage.value.position.y : state.help.y
					}
				}
				// superflous....?
				if (hi.dot) {
					switch (hi.arrow) {
						case 'down':
							hi.lock = {x: hi.x + 50 , y: hi.y + 90, x1: hi.x + 230, y1: hi.y + 220}
							break
					}
				}

				return hi
			}
1
			return hi
		},
		helpFinished: (state) => (helpStates.length - 2 < state.help.pointer)
	}
})
