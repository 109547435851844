import { createApp }         from "vue";
import App                   from "@/App.vue";
import router                from "@/router";
import VueJamIcons           from "@/components/icons/jam";
import Vue3TouchEvents       from "vue3-touch-events";
import Notifications         from "@kyvg/vue3-notification";
import VueSignaturePad       from "vue-signature-pad";
import { createI18n }        from 'vue-i18n'
import messages              from '@/lang/locales'
import VueGtag               from 'vue-gtag'
import { createPinia }       from 'pinia'
import { getLocalUserPrefs } from "@/utils";
import PiniaUndoRedo         from "@/store/undo-plugin"

// https://capacitorjs.com/docs/v3/basics/utilities
// Stop Soft Keyboard from pushing squeezing viewport.
addEventListener("load", function () {
	const viewport = document.querySelector("meta[name=viewport]");
	viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
})

// i18n setup
const dateTimeFormats = {
	'en': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric'
		}
	},
	'de': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric'
		}
	}
}
const numberFormats = {
	'en': {
		currency: {
			style: 'currency',
			currency: 'NZD'
		}
	},
	'de': {
		currency: {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: 'symbol'
		}
	}
}
// https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
const i18n = createI18n({
	legacy: false,
	locale: process.env.VUE_APP_I18N_LOCALE || "en",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
	dateTimeFormats,
	numberFormats,
	messages
})

// Undo?
const pinia = createPinia()
pinia.use(PiniaUndoRedo)


const app = createApp(App)
.use(i18n)
.use(pinia)
.use(Vue3TouchEvents)
.use(Notifications, {
	max: 1
})
.use(VueSignaturePad)
.use(router)
.use(VueJamIcons)
// opt-in / -out https://matteo-gabriele.gitbook.io/vue-gtag/custom-installation
.use(VueGtag, {
	bootstrap: (getLocalUserPrefs()?.ga === undefined),
	appName: 'Fizzler',
	pageTrackerScreenviewEnabled: true,
	config: {id: "G-2FKRCLBERX"},
	params: {
		anonymize_ip: true
	}
}, router)

app.mount("#app")

